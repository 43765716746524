var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { performAxiosRequest } from "./requestHelper";
import { log } from '../utils/logger';
import { postClient } from "./signoAPI";
export const submitClient = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postClient(body);
        log.debug('[Client service] POST get client token - response.status ==> ', response.status);
        return response.status === 200 && response.data;
    }), [400, 401], false);
});
