import { jsx as _jsx } from "react/jsx-runtime";
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import useLanguages from '../../../hooks/languages/useLanguages';
import { useTranslation } from 'react-i18next';
const MenuLanguages = ({ anchorEl, onClose }) => {
    const { t, i18n } = useTranslation();
    const { languages } = useLanguages();
    const open = Boolean(anchorEl);
    const handleMenuItemClick = (id) => {
        i18n.changeLanguage(id);
        onClose();
    };
    return (_jsx(Menu, Object.assign({ anchorEl: anchorEl, open: open, onClose: onClose, MenuListProps: {
            'aria-labelledby': 'language-button',
            role: 'listbox',
        } }, { children: languages &&
            languages.map((language) => (_jsx(MenuItem, Object.assign({ selected: language.id === i18n.language, onClick: () => handleMenuItemClick(language.id) }, { children: t(`languages.${language.tag}`) }), language.id))) })));
};
export default MenuLanguages;
