var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { log } from '../utils/logger';
import { fetchUserFormInfo, createUserForm, fetchUserFormResponses, sendUserFormResponses, sendFormResponseHistory } from '../api/formService';
import { useErrorBoundary } from 'react-error-boundary';
export const FormResponseContext = createContext(null);
export const FormResponseContextProvider = ({ children, userFormId, userFormCompletionDate, responses = [], }) => {
    const { getCompanyId } = useAuth();
    const [formResponses, setFormResponses] = useState(responses);
    const [formCompletionDate, setFormCompletionDate] = useState(userFormCompletionDate);
    const [currentFormId, setCurrentFormId] = useState(userFormId);
    const { showBoundary } = useErrorBoundary();
    const saveFormResponse = (formResponse, userFormId, contentTitle, currentSectionId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const formResponseExistsIndex = formResponses.findIndex((fr) => fr.contentId === formResponse.contentId);
            if (formResponseExistsIndex !== -1) {
                const updatedFormResponses = [...formResponses];
                updatedFormResponses[formResponseExistsIndex] = Object.assign(Object.assign({}, formResponse), { navBackLinkTo: formResponses[formResponseExistsIndex].navBackLinkTo, navNextLinkTo: formResponses[formResponseExistsIndex].navNextLinkTo });
                if (JSON.stringify(updatedFormResponses) !== JSON.stringify(formResponses)) {
                    setFormResponses(updatedFormResponses);
                    yield sendUserFormResponses(updatedFormResponses, userFormId);
                    const newValue = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.contentValue;
                    const oscVariable = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.ocs_variable;
                    const formResponseHistoryRequest = {
                        serviceFormFillingId: parseInt(userFormId),
                        currentSectionId,
                        questionType: formResponse.valueType,
                        questionId: formResponse.contentId,
                        questionTitle: contentTitle,
                        oldValue: null,
                        newValue: newValue,
                        oscEntity: formResponse.ocs_entity,
                        oscVariable,
                    };
                    yield sendFormResponseHistory([formResponseHistoryRequest]);
                }
            }
            else {
                setFormResponses([...formResponses, formResponse]);
                yield sendUserFormResponses([...formResponses, formResponse], userFormId);
                const newValue = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.contentValue;
                const oscVariable = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.ocs_variable;
                const formResponseHistoryRequest = {
                    serviceFormFillingId: parseInt(userFormId),
                    currentSectionId,
                    questionType: formResponse.valueType,
                    questionId: formResponse.contentId,
                    questionTitle: contentTitle,
                    oldValue: null,
                    newValue: newValue,
                    oscEntity: formResponse.ocs_entity,
                    oscVariable,
                };
                yield sendFormResponseHistory([formResponseHistoryRequest]);
            }
        }
        catch (err) {
            log.error('Error calling putUserFormResponses endpoint in saveFormResponse', err);
            showBoundary(err);
        }
    });
    const updateFormResponse = (formResponse, userFormId, contentTitle, currentSectionId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const formResponseExistsIndex = formResponses.findIndex((fr) => fr.contentId === formResponse.contentId);
            const updatedFormResponses = formResponses.slice(0, formResponseExistsIndex + 1);
            formResponse.navBackLinkTo = (_a = formResponses[formResponseExistsIndex - 1]) === null || _a === void 0 ? void 0 : _a.contentId;
            updatedFormResponses[formResponseExistsIndex] = formResponse;
            yield sendUserFormResponses(updatedFormResponses, userFormId);
            setFormResponses(updatedFormResponses);
            const newValue = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.contentValue;
            const oscVariable = formResponse.valueType === 'multiple' ? formResponse.ocs_variables.join(', ') : formResponse.ocs_variable;
            const formResponseHistoryRequest = {
                serviceFormFillingId: parseInt(userFormId),
                currentSectionId,
                questionType: formResponse.valueType,
                questionId: formResponse.contentId,
                questionTitle: contentTitle,
                oldValue: null,
                newValue,
                oscEntity: formResponse.ocs_entity,
                oscVariable,
            };
            yield sendFormResponseHistory([formResponseHistoryRequest]);
        }
        catch (err) {
            log.error('Error calling putUserFormResponses endpoint in saveFormResponse', err);
            showBoundary(err);
        }
    });
    const saveFormResponses = (responses, userFormId, contentTitle, currentSectionId, content) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const updatedResponses = [...formResponses];
            const listFormResponsesHistory = [];
            responses.forEach((response) => {
                var _a, _b;
                const newValue = response.valueType === 'multiple' ? response.ocs_variables.join(', ') : response.contentValue;
                const index = updatedResponses.findIndex((r) => r.contentId === response.contentId);
                if (index !== -1) {
                    updatedResponses[index].contentValue = response.contentValue;
                }
                else {
                    updatedResponses.push(response);
                }
                const multiContentQuestionTitle = (_b = (_a = content === null || content === void 0 ? void 0 : content.content) === null || _a === void 0 ? void 0 : _a.find(c => c.id == response.contentId)) === null || _b === void 0 ? void 0 : _b.label;
                listFormResponsesHistory.push({
                    serviceFormFillingId: parseInt(userFormId),
                    currentSectionId,
                    questionType: response.valueType,
                    questionId: response.contentId,
                    questionTitle: multiContentQuestionTitle !== null && multiContentQuestionTitle !== void 0 ? multiContentQuestionTitle : contentTitle,
                    oldValue: null,
                    newValue,
                    oscEntity: response.ocs_entity,
                    oscVariable: response.ocs_variables ? response.ocs_variables.join(',') : response.ocs_variable,
                    isIterativeContent: true,
                });
            });
            setFormResponses(updatedResponses);
            yield sendUserFormResponses(updatedResponses, userFormId);
            yield sendFormResponseHistory(listFormResponsesHistory);
        }
        catch (err) {
            log.error('Error calling putUserFormResponses endpoint in saveFormResponses', err);
            showBoundary(err);
        }
    });
    const removeFormResponseById = (responseId, userFormId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const previouslySavedResponsesFromApi = yield fetchUserFormResponses(userFormId);
            const filteredResponses = previouslySavedResponsesFromApi.responses.filter((response) => response.contentId !== responseId);
            setFormResponses([...filteredResponses]);
            yield sendUserFormResponses([...filteredResponses], userFormId);
        }
        catch (err) {
            log.error('Error calling getUserFormResponses or putUserFormResponses endpoints in removeFormResponseById', err);
            showBoundary(err);
        }
    });
    const updateFormResponseWithIterativeContent = (iterativeContentParentId, newResponses, isFirstPage, userFormId, contentTitle, currentSectionId, content, contentLabelTexts, navBackLinkTo) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            log.debug('Calling updateFormResponseWithIterativeContent');
            const iterativeResponseParent = getFormResponseById(iterativeContentParentId);
            const parentResponseIndex = formResponses === null || formResponses === void 0 ? void 0 : formResponses.findIndex((fr) => fr.contentId === iterativeContentParentId);
            iterativeResponseParent.iterativeContent.push({ content: [...newResponses] });
            (_b = iterativeResponseParent.navBackLinkTo) !== null && _b !== void 0 ? _b : (iterativeResponseParent.navBackLinkTo = navBackLinkTo);
            formResponses[parentResponseIndex] = iterativeResponseParent;
            setFormResponses([...formResponses]);
            yield sendUserFormResponses([...formResponses], userFormId);
            const listFormResponsesHistory = [];
            newResponses.forEach((fr) => {
                var _a, _b, _c;
                const label = (_a = contentLabelTexts.find(c => c.id == fr.contentId)) === null || _a === void 0 ? void 0 : _a.name;
                const newValue = fr.valueType === 'multiple' ? fr.ocs_variables.join(', ') : fr.contentValue;
                const multiContentQuestionTitle = label !== null && label !== void 0 ? label : (_c = (_b = content === null || content === void 0 ? void 0 : content.content) === null || _b === void 0 ? void 0 : _b.find(c => c.id == fr.contentId)) === null || _c === void 0 ? void 0 : _c.label;
                listFormResponsesHistory.push({
                    serviceFormFillingId: parseInt(userFormId),
                    currentSectionId,
                    questionType: fr.valueType,
                    questionId: fr.contentId,
                    questionTitle: multiContentQuestionTitle !== null && multiContentQuestionTitle !== void 0 ? multiContentQuestionTitle : contentTitle,
                    oldValue: null,
                    newValue,
                    oscEntity: fr.ocs_entity,
                    oscVariable: fr.ocs_variables ? fr.ocs_variables.join(',') : fr.ocs_variable,
                    isIterativeContent: true,
                });
            });
            yield sendFormResponseHistory(listFormResponsesHistory);
        }
        catch (err) {
            log.error('Error calling putUserFormResponses endpoint in updateFormResponseWithIterativeContent', err);
            showBoundary(err);
        }
    });
    const addFormResponseHistoryIterativePage = (newResponses, userFormId, contentTitle, currentSectionId, content) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            log.debug('Calling addFormResponseHistoryIterativePage');
            const listFormResponsesHistory = [];
            newResponses.forEach((fr) => {
                var _a, _b;
                const newValue = fr.valueType === 'multiple' ? fr.ocs_variables.join(', ') : fr.contentValue;
                const multiContentQuestionTitle = (_b = (_a = content === null || content === void 0 ? void 0 : content.content) === null || _a === void 0 ? void 0 : _a.find(c => c.id == fr.contentId)) === null || _b === void 0 ? void 0 : _b.label;
                listFormResponsesHistory.push({
                    serviceFormFillingId: parseInt(userFormId),
                    currentSectionId,
                    questionType: fr.valueType,
                    questionId: fr.contentId,
                    questionTitle: multiContentQuestionTitle !== null && multiContentQuestionTitle !== void 0 ? multiContentQuestionTitle : contentTitle,
                    oldValue: null,
                    newValue,
                    oscEntity: fr.ocs_entity,
                    oscVariable: fr.ocs_variables ? fr.ocs_variables.join(',') : fr.ocs_variable,
                    isIterativeContent: true,
                });
            });
            yield sendFormResponseHistory(listFormResponsesHistory);
        }
        catch (err) {
            log.error('Error calling sendFormResponseHistory endpoint in addFormResponseHistoryIterativePage', err);
            showBoundary(err);
        }
    });
    //Todo: eliminar de la tabla el elimiando en history form response
    const removeIterativeItem = (contentId, contentValue, iterativeContentParentId, userFormId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const parentResponse = getFormResponseById(iterativeContentParentId);
            const parentResponseIndex = formResponses === null || formResponses === void 0 ? void 0 : formResponses.findIndex((fr) => fr.contentId === (parentResponse === null || parentResponse === void 0 ? void 0 : parentResponse.contentId));
            const iterativeContents = parentResponse.iterativeContent;
            const iterativeItemToDelete = iterativeContents === null || iterativeContents === void 0 ? void 0 : iterativeContents.find((iterativeItemArray) => {
                const isTheOneToDelete = iterativeItemArray.content.find((response) => (response === null || response === void 0 ? void 0 : response.contentId) === contentId && response.contentValue === contentValue);
                if (isTheOneToDelete)
                    return iterativeItemArray;
            });
            //borrar en iterative Content, el array iterativeItem encontrado
            parentResponse.iterativeContent = iterativeContents === null || iterativeContents === void 0 ? void 0 : iterativeContents.filter((iterativeItem) => iterativeItem !== iterativeItemToDelete);
            formResponses[parentResponseIndex] = parentResponse;
            setFormResponses([...formResponses]);
            yield sendUserFormResponses([...formResponses], userFormId);
        }
        catch (err) {
            log.error('Error calling putUserFormResponses endpoint in removeIterativeItem', err);
            showBoundary(err);
        }
    });
    const getFormResponseById = (contentId) => {
        return formResponses.find((formResponse) => (formResponse === null || formResponse === void 0 ? void 0 : formResponse.contentId) === contentId);
    };
    const getFormResponses = () => {
        return formResponses;
    };
    const getUserFormId = () => {
        return currentFormId;
    };
    const getFormCompletionDate = () => {
        return formCompletionDate;
    };
    const saveUserFormId = (formId) => {
        setCurrentFormId(formId);
    };
    const value = {
        formResponses,
        getUserFormId,
        saveUserFormId,
        saveFormResponse,
        saveFormResponses,
        removeFormResponseById,
        getFormResponseById,
        getFormResponses,
        updateFormResponseWithIterativeContent,
        removeIterativeItem,
        getFormCompletionDate,
        updateFormResponse,
        addFormResponseHistoryIterativePage
    };
    useEffect(() => {
        log.debug('form response context effect');
        if (!currentFormId && getCompanyId) {
            fetchUserFormInfo(getCompanyId())
                .then((existingForm) => {
                if (existingForm) {
                    setCurrentFormId(existingForm.id);
                    setFormCompletionDate(existingForm.formCompletionDate);
                    const responses = existingForm.formResponses ? JSON.parse(existingForm.formResponses).responses : [];
                    (responses === null || responses === void 0 ? void 0 : responses.length) > 0 && setFormResponses([...formResponses, ...responses]);
                    /*
                    log.debug('form response context effect - existing form info: ', {
                      formId: existingForm.id,
                      formResponses: responses,
                      formCompletionDate: existingForm.formCompletionDate,
                    });
                    */
                }
                else {
                    log.debug('No existing form, should trigger createUserForm');
                    createUserForm(getCompanyId())
                        .then((newUserForm) => {
                        log.debug('form response context effect - created new form: ', {
                            newFormId: newUserForm.id,
                            companyId: getCompanyId(),
                        });
                        saveUserFormId(newUserForm.id);
                    })
                        .catch((err) => {
                        showBoundary(err);
                        log.error('Error calling createUserForm endpoint', err);
                    });
                }
            })
                .catch((err) => {
                showBoundary(err);
                log.error('Error calling getUserFormInfo endpoint', err);
            });
        }
    }, [currentFormId, formResponses, getCompanyId, showBoundary]);
    return _jsx(FormResponseContext.Provider, Object.assign({ value: value }, { children: children }));
};
export default FormResponseContext;
