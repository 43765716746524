import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { es } from 'date-fns/locale';
const DateLocaleContext = createContext(null);
export const DateLocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState(es);
    return (_jsx(DateLocaleContext.Provider, Object.assign({ value: { locale, setLocale } }, { children: children })));
};
export const useDateLocale = () => {
    return useContext(DateLocaleContext);
};
