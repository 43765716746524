import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import DisclaimerContainer from '../DisclaimerContainer';
export var ErrorEnum;
(function (ErrorEnum) {
    ErrorEnum["GENERIC"] = "genericError";
    ErrorEnum["REGISTRATION_INVALID_COLLECTIVE"] = "registrationInvalidCollectiveIdError";
    ErrorEnum["REGISTRATION_ERROR"] = "registrationError";
    ErrorEnum["PASSWORD_RESET"] = "passwordResetError";
    ErrorEnum["PAGE_NOT_FOUND"] = "pageNotFoundError";
})(ErrorEnum || (ErrorEnum = {}));
const ErrorPage = ({ error }) => {
    const { t } = useTranslation();
    const getDescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: (t(`texts.errorPageTexts.${error}.description`)) };
    };
    const getSubdescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: (t(`texts.errorPageTexts.${error}.subdescription`)) };
    };
    return (_jsxs(DisclaimerContainer, { children: [_jsx(Helmet, { children: _jsxs("title", { children: ["Signo | ", t('texts.errorPageTexts.pageTitle')] }) }), _jsx("h1", Object.assign({ className: "disclaimer-page__title", "data-testid": "error-page-title" }, { children: t('texts.errorPageTexts.pageTitle') })), _jsx("div", { className: "disclaimer-page__description", "data-testid": "error-page-description", dangerouslySetInnerHTML: getDescription() }), getSubdescription() !== undefined && (_jsx("div", { className: "disclaimer-page__subdescription", "data-testid": "error-page-subdescription", dangerouslySetInnerHTML: getSubdescription() }))] }));
};
export default ErrorPage;
