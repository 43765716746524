import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import MainContainer from './MainContainer';
import MenuLanguages from '../../components/common/MenuLanguages/MenuLanguages';
import IconButton from '@mui/material/IconButton/IconButton';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { capitaliceWord } from '../../utils/textUtils';
import { useTranslation } from 'react-i18next';
const DisclaimerContainer = ({ children, collectivePersonalization, isCollectiveApiResponded }) => {
    const { t, i18n } = useTranslation();
    //LANGUAGE
    const [anchorLanguage, setAnchorLanguage] = React.useState(null);
    const handleOpenLanguage = (event) => {
        setAnchorLanguage(event.currentTarget);
    };
    const handleCloseLanguage = () => {
        setAnchorLanguage(null);
    };
    return (_jsx(MainContainer, Object.assign({ collectivePersonalization: collectivePersonalization, isCollectiveApiResponded: isCollectiveApiResponded }, { children: _jsxs("div", Object.assign({ className: "main-content disclaimer-page" }, { children: [_jsxs("div", Object.assign({ className: "language-registration" }, { children: [_jsxs(IconButton, Object.assign({ onClick: handleOpenLanguage, "aria-label": t('common.ariaLabels.languages') }, { children: [_jsx(LanguageOutlinedIcon, {}), _jsx("span", Object.assign({ className: "language-text" }, { children: capitaliceWord(i18n.language) }))] })), _jsx(MenuLanguages, { anchorEl: anchorLanguage, onClose: handleCloseLanguage })] })), children] })) })));
};
export default DisclaimerContainer;
