var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useClientAuth } from '../contexts/ClientContext';
import { submitClient } from '../api/clientService';
export const ProtectedClientRoute = () => {
    const { clientToken, setClientToken } = useClientAuth();
    useEffect(() => {
        const fetchToken = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const clientId = window._env_.REACT_APP_CLIENT_ID;
                const clientSecret = window._env_.REACT_APP_CLIENT_SECRET;
                const response = yield submitClient({ clientId, clientSecret });
                setClientToken(response.accessToken); // Guarda el token en el estado
            }
            catch (error) {
                console.error('Error obteniendo el token:', error);
            }
        });
        fetchToken();
    }, []);
    if (clientToken === undefined || clientToken === null) {
        return null; // No renderiza nada mientras se espera el token
    }
    if (!clientToken) {
        return _jsx(Navigate, { to: "/login" });
    }
    return (_jsx(Outlet, {}));
};
