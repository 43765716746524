var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationBinaryQuestion from './Questions/RegistrationBinaryQuestion';
import { updateRegistrationResponse, updateRegistrationResponses } from './Questions/RegistrationResponse';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
const Staff = ({ saveResponse, handleNextButton }) => {
    const { t } = useTranslation();
    const [responses, setResponses] = useState([]);
    const [isNumberOfEmployeesTextQuestionEnabled, setIsNumberOfEmployeesTextQuestionEnabled] = useState(false);
    useEffect(() => {
        const hasEmployeesAndTextQuestionFulfilled = responses.find((s) => s.name == 'has_employees' && s.value == true) && responses.find((s) => s.name == 'employees' && s.value !== 0);
        const hasNoEmployees = responses.find((s) => s.name == 'has_employees' && s.value == false);
        if (hasEmployeesAndTextQuestionFulfilled || hasNoEmployees) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, isNumberOfEmployeesTextQuestionEnabled]);
    const hasEmployeesBinaryQuestion = {
        description: '¿La empresa tiene personal contratado?',
        questionValues: [
            {
                value: '0',
                text: t('common.no'),
            },
            {
                value: '1',
                text: t('common.yes'),
            },
        ],
    };
    const numberOfEmployeesTextQuestion = {
        label: '¿Cuántas personas?',
        placeholder: 'Escribe la cantidad',
    };
    const onClick = (questionValue) => __awaiter(void 0, void 0, void 0, function* () {
        const response_has_employees = {
            name: 'has_employees',
            value: questionValue !== '0',
        };
        const response_employees = {
            name: 'employees',
            value: 0,
        };
        if (questionValue === '0') {
            saveResponse(response_employees);
            setIsNumberOfEmployeesTextQuestionEnabled(false);
        }
        else {
            setIsNumberOfEmployeesTextQuestionEnabled(true);
        }
        updateResponses([response_employees, response_has_employees]);
    });
    const onChange = (value, name) => {
        const response = {
            name: name,
            value: Number(value),
        };
        saveResponse(response);
        updateResponse(response);
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const updateResponses = (currentResponses) => {
        const newResponses = updateRegistrationResponses(responses, currentResponses);
        setResponses(newResponses);
    };
    return (_jsxs("div", Object.assign({ className: "registration-page__content" }, { children: [_jsx("h2", Object.assign({ className: "registration-page__content-subtitle", "data-testid": 'staff-page-title' }, { children: t('texts.registrationPageTexts.staffScreen.subtitle').toUpperCase() })), _jsxs("div", Object.assign({ className: "registration-page__content-body" }, { children: [_jsx("div", Object.assign({ className: "registration-page__content-question multi-content" }, { children: _jsx(RegistrationBinaryQuestion, { content: hasEmployeesBinaryQuestion, onClick: onClick }) })), _jsx("div", Object.assign({ className: "registration-page__content-question__employees" }, { children: _jsx(RegistrationTextQuestion, { id: 'employees', content: numberOfEmployeesTextQuestion, onChange: onChange, fieldName: 'employees', enabled: isNumberOfEmployeesTextQuestionEnabled, isEmpty: !isNumberOfEmployeesTextQuestionEnabled, type: "number", direction: 'text-question-row' }) }))] }))] })));
};
export default Staff;
