var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchLatestGeneratedCPSDocumentation, fetchLatestGeneratedPDDDocumentation } from '../../../api/signoAPI';
import { useAuth } from '../../../contexts/AuthContext';
import documentsNames from '../../../data/documentsNames.json';
import { DocumentationAlertMessage, DocumentType, } from '../../../types/DocumentationTypes';
import { log } from '../../../utils/logger';
import PrimaryOutlineButton from '../../common/Buttons/PrimaryOutlineButton';
import GlobalContainer from '../../common/Containers/GlobalContainer';
import Header from '../../common/Header/Header';
import DocumentsSection from './DocumentsSection';
export const documentsAdditionalInfo = {
    'DOC1_PDD_SGP.pdf': {
        displayOrder: 0,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Manual de Gestión de Privacidad.pdf',
    },
    'DOC2_PDD_AARR.pdf': {
        displayOrder: 1,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Análisis de riesgos.pdf',
    },
    'DOC3_PDD_INEIPD.pdf': {
        displayOrder: 2,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Informe de necesidad de evaluación de impacto.pdf',
    },
    'DOC31_PDD_EIPD.pdf': {
        displayOrder: 3,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Evaluación de impacto en la protección de datos.pdf',
    },
    'DOC4_PDD_MCNPD.pdf': {
        displayOrder: 4,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Políticas y procedimientos.pdf',
    },
    'DOC5_PDD_PCNSI.pdf': {
        displayOrder: 5,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Políticas y procedimientos.pdf',
    },
    'DOC6_PDD_OI.pdf': {
        displayOrder: 6,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Organización interna y documentación para el personal.pdf',
    },
    'DOC7_PDD_SRT.pdf': {
        displayOrder: 7,
        userGuideUrl: 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Guía explicativa Seguridad en la relación con terceros.pdf',
    },
    'DOC_Videovigilancia.pdf': {
        displayOrder: 8,
        userGuideUrl: '',
    },
};
const Documentation = () => {
    const { t } = useTranslation();
    const [pddDocumentsFromApi, setPddDocumentsFromApi] = useState([]);
    const [cpsDocumentsFromApi, setCpsDocumentsFromApi] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const { getCompanyId } = useAuth();
    const navigate = useNavigate();
    // TODO: refactor useEffect
    useEffect(() => {
        const intervalCallForPDDdocuments = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
            fetchLatestGeneratedPDDDocumentation(getCompanyId())
                .then((latestGeneratedPddDocsResponse) => {
                log.debug('Status 200 RESPONSE FOR PDD DOCS ====> ', latestGeneratedPddDocsResponse);
                if (latestGeneratedPddDocsResponse.data.documents.length > 0) {
                    log.debug('200 - PDD DOCUMENTS EXIST ====> ', latestGeneratedPddDocsResponse.data.documents);
                    setAlertMessage(null);
                    setPddDocumentsFromApi(latestGeneratedPddDocsResponse.data.documents);
                    clearInterval(intervalCallForPDDdocuments);
                }
                else {
                    log.debug('200 - PDD DOCUMENTS DO NOT EXIST, BEING GENERATED ====> ', latestGeneratedPddDocsResponse.data.documents);
                    setAlertMessage(DocumentationAlertMessage.GENERATING);
                }
            })
                .catch((err) => {
                var _a, _b;
                if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                    log.debug('404 - PDD DOCUMENTS DO NOT EXIST, NO FORM COMPLETED ====> ', err);
                    setAlertMessage(DocumentationAlertMessage.NOTFOUND);
                    clearInterval(intervalCallForPDDdocuments);
                }
                else if (((_b = err.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
                    log.debug('401 - PDD CALL UNAUTHORIZED ====> ', err);
                    setAlertMessage(DocumentationAlertMessage.UNAUTHORIZED);
                    clearInterval(intervalCallForPDDdocuments);
                }
            });
        }), 1000);
        const intervalCallForCPSdocuments = setInterval(() => __awaiter(void 0, void 0, void 0, function* () {
            fetchLatestGeneratedCPSDocumentation(getCompanyId())
                .then((latestGeneratedCpsDocsResponse) => {
                log.debug('Status 200 RESPONSE FOR CPS DOCS  ====> ', latestGeneratedCpsDocsResponse);
                if (latestGeneratedCpsDocsResponse.data.documents.length > 0) {
                    log.debug('200 - CPS DOCUMENTS EXIST ====> ', latestGeneratedCpsDocsResponse.data.documents);
                    setCpsDocumentsFromApi(latestGeneratedCpsDocsResponse.data.documents);
                    clearInterval(intervalCallForCPSdocuments);
                }
                else {
                    log.debug('200 - CPS DOCUMENTS DO NOT EXIST ====> ', latestGeneratedCpsDocsResponse.data.documents);
                    clearInterval(intervalCallForCPSdocuments);
                }
            })
                .catch((err) => {
                var _a, _b;
                if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                    log.debug('404 - CPS DOCUMENTS DO NOT EXIST ====> ', err);
                    clearInterval(intervalCallForCPSdocuments);
                }
                else if (((_b = err.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
                    log.debug('401 - CPS CALL UNAUTHORIZED ====> ', err);
                    clearInterval(intervalCallForCPSdocuments);
                }
            });
        }), 1000);
    }, [getCompanyId]);
    const renderAlertMessage = () => {
        switch (alertMessage) {
            case DocumentationAlertMessage.GENERATING:
                return (_jsxs("div", Object.assign({ className: "alert-message alert alert-info", role: "alert" }, { children: [_jsx("i", { className: "bi bi-clock-history" }), _jsx("span", { children: t('texts.documentationPageTexts.documentsAreBeingGeneratedMessage') })] })));
            case DocumentationAlertMessage.NOTFOUND:
                return (_jsxs("div", Object.assign({ className: "alert-message alert alert-danger", role: "alert" }, { children: [_jsx("i", { className: "bi bi-exclamation-circle-fill" }), _jsx("span", { children: t('texts.documentationPageTexts.documentsNotFoundMessage') })] })));
            case DocumentationAlertMessage.UNAUTHORIZED:
                return (_jsxs("div", Object.assign({ className: "alert-message alert alert-danger", role: "alert" }, { children: [_jsx("i", { className: "bi bi-exclamation-circle-fill" }), _jsx("span", { children: t('texts.documentationPageTexts.unauthorizedMessage') })] })));
            default:
                return null;
        }
    };
    const getAllDocumentsList = () => {
        const allDocumentsList = [];
        if (pddDocumentsFromApi.length > 0) {
            const pddDocumentsToDisplay = pddDocumentsFromApi
                .map((pddDocument) => {
                var _a, _b;
                //TODO esto con el tema de los idiomas seguramente falle
                const documentTexts = documentsNames.texts.documentationPageTexts.documents[pddDocument.documentName];
                //const documentTexts: any= `texts.documentationPageTexts.documents.${pddDocument.documentName}`;
                return {
                    id: pddDocument.id,
                    documentName: documentTexts ? documentTexts.name : '',
                    fileUrl: pddDocument.fileUrl,
                    documentDescription: documentTexts === null || documentTexts === void 0 ? void 0 : documentTexts.description,
                    userGuideUrl: (_a = documentsAdditionalInfo[pddDocument.documentName]) === null || _a === void 0 ? void 0 : _a.userGuideUrl,
                    order: (_b = documentsAdditionalInfo[pddDocument.documentName]) === null || _b === void 0 ? void 0 : _b.displayOrder,
                };
            })
                .sort((a, b) => a.order - b.order);
            allDocumentsList.push({ type: DocumentType.PDD, list: pddDocumentsToDisplay });
        }
        if (cpsDocumentsFromApi.length > 0) {
            const cpsDocumentsToDisplay = cpsDocumentsFromApi.map((cpsDocument) => {
                return {
                    id: cpsDocument.id,
                    documentName: cpsDocument.documentName,
                    fileUrl: cpsDocument.fileUrl,
                };
            });
            allDocumentsList.push({ type: DocumentType.CPS, list: cpsDocumentsToDisplay });
        }
        return allDocumentsList;
    };
    const goBack = () => {
        // TODO: esto hay que cambiarlo para hacer un go.back
        navigate('/form');
    };
    log.debug('allDocumentsList', getAllDocumentsList());
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: t('texts.documentationPageTexts.pageTitle') }) }), _jsxs(GlobalContainer, { children: [_jsx(Header, { title: t('texts.documentationPageTexts.pageTitle') }), _jsxs("div", Object.assign({ className: "main-content documentation-page" }, { children: [_jsxs("h1", Object.assign({ className: "documentation-page__title" }, { children: [t('texts.documentationPageTexts.pageTitle'), " /", ' ', _jsx("span", Object.assign({ className: "documentation-page__title-service" }, { children: t('texts.documentationPageTexts.serviceTitle') }))] })), _jsx("div", Object.assign({ className: "documentation-page__body" }, { children: alertMessage
                                    ? renderAlertMessage()
                                    : getAllDocumentsList().length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "documentation-page__body-description" }, { children: [_jsx("p", { children: t('texts.documentationPageTexts.pageDescription') }), _jsx("p", { dangerouslySetInnerHTML: { __html: t('texts.documentationPageTexts.generalDocumentationGuideDescription') } }), _jsx("p", { children: _jsxs("a", Object.assign({ href: "https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/Gui\u0301a general documentacio\u0301n.pdf", target: "_blank", rel: "noreferrer", className: "documentation-page__body-general-guide-link" }, { children: [t('texts.documentationPageTexts.generalDocumentationGuideLink').toUpperCase(), _jsx("i", { className: "bi bi-file-text" })] })) })] })), getAllDocumentsList().map((documentsList, index) => {
                                                return _jsx(DocumentsSection, { documentsList: documentsList }, index);
                                            })] })) })), _jsx("div", Object.assign({ className: "documentation-page__go-back-btn" }, { children: _jsx(PrimaryOutlineButton, { text: t('texts.documentationPageTexts.goBackButton'), onClickFn: goBack, testId: "go-back-btn", enabled: true, submitButton: false }) }))] }))] })] }));
};
export default Documentation;
