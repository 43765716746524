import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './i18n';
// Importing the Bootstrap CSS and icons
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
// Importing the custom CSS
import './scss/styles.scss';
import ClientProvider from './contexts/ClientContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(I18nextProvider, Object.assign({ i18n: i18n }, { children: _jsx(ClientProvider, { children: _jsx(App, {}) }) })));
