import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import IconButton from '@mui/material/IconButton/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setSnackbarCallback } from '../../../api/snackbarSrv';
import './SnackbarFeedback.scss';
const SnackbarFeedback = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('error');
    // Esta función se pasa desde el servicio global
    useEffect(() => {
        const handleSnackbar = (message, type) => {
            setMessage(message);
            setType(type);
            setOpen(true);
        };
        // Establece la función que maneja el snackbar
        setSnackbarCallback(handleSnackbar);
    }, []);
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsx(Snackbar, Object.assign({ className: `snackbar-feedback ${type}`, open: open, autoHideDuration: 3000, onClose: handleClose }, { children: _jsxs("div", Object.assign({ className: "snackbar-feedback_wrapper" }, { children: [type === 'error' ? (_jsx(ErrorOutlineOutlinedIcon, {})) : type === 'info' ? (_jsx(InfoOutlinedIcon, {})) : type === 'warning' ? (_jsx(WarningAmberRoundedIcon, {})) : (_jsx(TaskAltRoundedIcon, {})), _jsxs("div", { children: [_jsx("p", Object.assign({ className: "title" }, { children: type === 'error'
                                ? t('common.snackbar.errorTitle')
                                : type === 'info'
                                    ? t('common.snackbar.infoTitle')
                                    : type === 'warning'
                                        ? t('common.snackbar.warningTitle')
                                        : t('common.snackbar.successTitle') })), _jsx("p", Object.assign({ className: "message" }, { children: message }))] }), _jsx(IconButton, Object.assign({ "aria-label": t('common.btn.close'), onClick: handleClose }, { children: _jsx(CloseRoundedIcon, {}) }))] })) })));
};
export default SnackbarFeedback;
