import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import { ContentType } from '../../../../../types/FormModelTypes';
import { INFO_ICON_BLUE_URL } from '../../../../../utils/constants';
import NextButton from '../../../../common/Buttons/NextButton';
import TextQuestion from '../CommonQuestions/TextQuestion';
import BinaryQuestion from './Questions/BinaryQuestion';
import MultipleQuestion from './Questions/MultipleQuestion';
import { scrollToTop } from '../../../../../utils/common-utils';
const SingleContentContainer = ({ content, goToNextContent, alertContent }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [selectedResponse, setSelectedResponse] = useState(null);
    const { saveFormResponse, getUserFormId } = useContext(FormResponseContext);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    useEffect(() => {
        setSelectedResponse(content.formResponse);
    }, [content.formResponse]);
    const handleOnClick = (responseValue) => {
        var _a;
        responseValue.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo;
        responseValue.navNextLinkTo = (_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo;
        setSelectedResponse(responseValue);
    };
    const handleOnChange = (response) => {
        var _a;
        response.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo;
        response.navNextLinkTo = (_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo;
        response.contentValue === '' ? setSelectedResponse(null) : setSelectedResponse(response);
    };
    const renderInfoText = (questionType, questionIsRequired) => {
        return questionType === ContentType.MULTIPLE
            ? questionIsRequired
                ? t('texts.responsesInfoTexts.multipleQuestionRequired')
                : t('texts.responsesInfoTexts.multipleQuestionNotRequired')
            : questionType === ContentType.TEXT
                ? questionIsRequired
                    ? t('texts.responsesInfoTexts.textQuestionRequired')
                    : t('texts.responsesInfoTexts.textQuestionNotRequired')
                : null;
    };
    const canGoToNextContent = !content.questionRequired ||
        content.type === ContentType.BINARY ||
        (content.type === ContentType.TEXT && content.questionRequired && !!selectedResponse) ||
        (content.type === ContentType.MULTIPLE && ((_a = selectedResponse === null || selectedResponse === void 0 ? void 0 : selectedResponse.ocs_variables) === null || _a === void 0 ? void 0 : _a.length) > 0);
    const handleNextContent = () => {
        var _a;
        selectedResponse && saveFormResponse(selectedResponse, getUserFormId(), t(content.title), getCurrentSectionId());
        setSelectedResponse(null);
        goToNextContent((_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo);
        scrollToTop();
    };
    const renderQuestion = () => {
        switch (content === null || content === void 0 ? void 0 : content.type) {
            case ContentType.BINARY:
                return _jsx(BinaryQuestion, { content: content, goToNextContent: goToNextContent, alertContent: alertContent });
            case ContentType.MULTIPLE:
                return _jsx(MultipleQuestion, { content: content, handleOnClick: handleOnClick });
            case ContentType.TEXT:
                return _jsx(TextQuestion, { content: content, handleOnChange: handleOnChange });
            default:
        }
    };
    const questionDescription = { __html: t(content.description) };
    return (_jsxs("div", Object.assign({ className: "question", "data-testid": "question-component" }, { children: [_jsxs("div", Object.assign({ className: "question__body" }, { children: [_jsx("div", Object.assign({ "data-testid": "content-title", className: "question__title" }, { children: t(content.title) })), ((_b = content.description) === null || _b === void 0 ? void 0 : _b.length) > 0 && _jsx("div", { className: "question__description", dangerouslySetInnerHTML: questionDescription })] })), _jsxs("div", { children: [renderQuestion(), content.nextButton && (_jsxs("div", Object.assign({ className: "question__next-action" }, { children: [_jsx("div", Object.assign({ className: "question__info" }, { children: _jsxs("p", Object.assign({ className: "question__info-text", "data-testid": "question-info-text" }, { children: [_jsx("img", { className: "question__info-icon", alt: "Info icon", src: INFO_ICON_BLUE_URL }), renderInfoText(content.type, content.questionRequired)] })) })), _jsx(NextButton, { onClickFn: handleNextContent, text: content.nextButton.title, enabled: canGoToNextContent })] })))] })] })));
};
export default SingleContentContainer;
