import languagesMock from '../../data/__mocks__/languages.json';
import { callServicePromise } from '../_callService';
import { URL_API } from '../API_BASE_URL';
function getLanguagesSrv() {
    const mock = true;
    if (mock || window._env_.REACT_APP_MOCK === 'true') {
        return Promise.resolve(languagesMock); // Devuelve el mock
    }
    const request = {
        method: 'get',
        url: `${URL_API}/language`,
    };
    return callServicePromise(request);
}
export default getLanguagesSrv;
