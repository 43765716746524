import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
export const Modalx = ({ show = false, fullScreenQuery, id, header, scrollableContent = false, cssClass, onClose, title, children, footer, }) => {
    const [showClass, setShowClass] = useState(false);
    const [displayBlock, setDisplayBlock] = useState(false);
    useEffect(() => {
        if (show) {
            setDisplayBlock(true);
            const timer = setTimeout(() => {
                setShowClass(true);
            }, 10);
            return () => clearTimeout(timer);
        }
        else {
            setShowClass(false);
            const timer = setTimeout(() => {
                setDisplayBlock(false);
            }, 300);
            return () => clearTimeout(timer);
        }
    }, [show]);
    return (_jsx("div", Object.assign({ style: { display: displayBlock ? 'block' : 'none' }, className: `modalx modal fade ${showClass ? 'show' : ''} ${cssClass || ''}`, id: id, "data-bs-backdrop": "static", "data-bs-keyboard": "false", tabIndex: -1, "aria-hidden": show ? 'false' : 'true', "aria-labelledby": "label" }, { children: _jsx("div", Object.assign({ className: `modal-dialog modal-dialog-centered ${scrollableContent ? 'modal-dialog-scrollable' : ''} ${fullScreenQuery ? fullScreenQuery : ''}` }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [header && (_jsxs("div", Object.assign({ className: "modal-header" }, { children: [_jsx("h4", Object.assign({ className: "modal-title", id: "label" }, { children: title })), _jsx("button", { onClick: onClose, type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" })] }))), _jsx("div", Object.assign({ className: "modal-body" }, { children: children })), footer && _jsx("div", Object.assign({ className: "modal-footer" }, { children: footer }))] })) })) })));
};
