import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import DropdownQuestion from './DropdownQuestion';
import { provincesValues } from '../../../../../utils/provinces';
const DropdownProvinceQuestion = ({ content, handleOnChange }) => {
    const [questionValues, setQuestionValues] = useState([]);
    useEffect(() => {
        setQuestionValues(provincesValues);
    }, []);
    return _jsx(DropdownQuestion, { content: Object.assign({}, content, { questionValues: questionValues }), handleOnChange: handleOnChange });
};
export default DropdownProvinceQuestion;
