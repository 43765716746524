import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import { useTranslation } from 'react-i18next';
const IterativeResumeItem = ({ index, item, iterativeContentParentId }) => {
    const { t } = useTranslation();
    const { removeIterativeItem, getUserFormId } = useContext(FormResponseContext);
    return (_jsx("div", Object.assign({ className: "card" }, { children: _jsxs("div", Object.assign({ className: "card-header" }, { children: [_jsxs("div", Object.assign({ className: "card-header__text", "data-testid": `iterative-items-${index}` }, { children: [item.value, ' ', item.additionalInfo && (_jsxs("span", Object.assign({ className: "card-header__text-additional-info", "data-testid": `iterative-items-additional-info-${index}` }, { children: [' ', "- ", t(item.additionalInfo)] })))] })), _jsxs("button", Object.assign({ className: "button--delete", "data-testid": `delete-button-${index}`, onClick: () => {
                        removeIterativeItem(item.contentId, item.value, iterativeContentParentId, getUserFormId());
                    } }, { children: [_jsxs("div", Object.assign({ className: "button--delete__text" }, { children: [t('texts.iterativeResumeTexts.delete').toUpperCase(), " "] })), _jsx("i", { className: "bi bi-trash" })] }))] })) })));
};
export default IterativeResumeItem;
