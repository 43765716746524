import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import VerticalLine, { VerticalLinePositionEnum } from './VerticalLine';
import { useTranslation } from 'react-i18next';
const SubsectionItem = ({ subsection, currentSectionId }) => {
    const { t } = useTranslation();
    const isActive = subsection.id === currentSectionId;
    return (_jsxs("div", Object.assign({ "data-testid": 'subsection', className: 'subsection-item' }, { children: [_jsx(VerticalLine, { transparent: false, position: VerticalLinePositionEnum.MIDDLE }), _jsx("p", Object.assign({ className: `subsection-item__text ${isActive ? 'subsection-item__text--active' : ''}
        ${subsection.isCompleted ? 'subsection-item__text--completed' : ''}` }, { children: t(subsection.sectionTitle) }))] })));
};
export default SubsectionItem;
