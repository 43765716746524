import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import FormProgressContext, { FormStatus } from '../../../contexts/FormProgressContext';
import languae from '../../../data/header.text.json';
import { LOGO_URL } from '../../../utils/constants';
import { capitaliceWord } from '../../../utils/textUtils';
import Notifications from '../../pages/Notifications/Notifications';
import MenuLanguages from '../MenuLanguages/MenuLanguages';
import ContactInfoModal from '../Modals/ContactInfo/ContactInfoModal';
import ContactInfoModalWithResetLink from '../Modals/ContactInfo/ContactInfoModalWithResetLink';
import './header.scss';
const headerMenu = languae.header.headerMenu;
const footerMenu = languae.header.footerMenu;
const Header = (props) => {
    const { t, i18n } = useTranslation();
    const { logout } = useAuth();
    //const { infoCompany } = useInfoCompany();
    const { getFormProgressStatus } = useContext(FormProgressContext);
    const navigate = useNavigate();
    const title = props.title || 'Protección de datos';
    //LANGUAGE
    const [anchorLanguage, setAnchorLanguage] = React.useState(null);
    const handleOpenLanguage = (event) => {
        setAnchorLanguage(event.currentTarget);
    };
    const handleCloseLanguage = () => {
        setAnchorLanguage(null);
    };
    //Menu general
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const openMenu = Boolean(anchorMenu);
    const handleMainMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorMenu(null);
    };
    const handleNavigate = (url) => {
        navigate(url);
    };
    const logOutHandle = () => {
        logout();
    };
    //NOTIFICATIONS
    //TODO Aqui supuestamente se actualizan las notificaciones
    /*   const { newNotifications, refreshNewNotifications } = useNewNotifications(); */
    const [notiPopover, setNotiPopover] = React.useState(null);
    const handleOpenNotiPopover = (event) => {
        setNotiPopover(event.currentTarget);
    };
    const handleCloseNotiPopover = () => {
        setNotiPopover(null);
    };
    return (_jsx("div", Object.assign({ className: "PreHeader" }, { children: _jsxs("header", Object.assign({ className: "header" }, { children: [_jsx("img", { alt: "SIGNO", onClick: () => navigate('/'), className: "header-logo", src: LOGO_URL }), _jsx("div", Object.assign({ className: "header-title" }, { children: _jsx("h1", Object.assign({ className: "title" }, { children: t('common.title') })) })), _jsxs("div", Object.assign({ className: "header-tools" }, { children: [_jsx(Tooltip, Object.assign({ title: "Idioma", placement: "top" }, { children: _jsxs(IconButton, Object.assign({ onClick: handleOpenLanguage, "aria-label": t('common.ariaLabels.languages') }, { children: [_jsx(LanguageOutlinedIcon, {}), _jsx("span", Object.assign({ className: "language-text" }, { children: capitaliceWord(i18n.language) }))] })) })), _jsx(Tooltip, Object.assign({ title: "Cerrar sesi\u00F3n", placement: "top" }, { children: _jsx(IconButton, Object.assign({ onClick: logOutHandle, "aria-label": 'Cerrar sesión' }, { children: _jsx(LogoutIcon, { fontSize: "small" }) })) })), _jsx(MenuLanguages, { anchorEl: anchorLanguage, onClose: handleCloseLanguage })] })), getFormProgressStatus() !== FormStatus.NOT_STARTED ? _jsx(ContactInfoModalWithResetLink, {}) : _jsx(ContactInfoModal, {}), _jsx(Notifications, { notiPopover: notiPopover, handleCloseNotiPopover: handleCloseNotiPopover })] })) })));
};
export default Header;
