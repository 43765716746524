import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkUsernameExists, isLegalPersonNumberValid } from '../../../../api/signoAPI';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
const LegalDataCompany = ({ saveResponse, handleNextButton }) => {
    const { t } = useTranslation();
    const [responses, setResponses] = useState([]);
    const [nifIsInvalid, setNifIsInvalid] = useState(false);
    const [isNifDuplicated, setIsNifDuplicated] = useState(false);
    useEffect(() => {
        if (nifIsInvalid) {
            const errorElement = document.getElementById('nif-is-invalid');
            errorElement === null || errorElement === void 0 ? void 0 : errorElement.scrollIntoView();
        }
        else if (isNifDuplicated) {
            const errorElement = document.getElementById('nif-is-duplicated');
            errorElement === null || errorElement === void 0 ? void 0 : errorElement.scrollIntoView();
        }
        if ((responses === null || responses === void 0 ? void 0 : responses.length) == 2 && !nifIsInvalid && !isNifDuplicated) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, nifIsInvalid, isNifDuplicated]);
    const businessNameTextQuestion = {
        label: '¿Cuál es la razón social de la empresa?',
        placeholder: 'Escribe el nombre de la empresa',
    };
    const documentIdTextQuestion = {
        label: '¿Y cuál es el NIF?',
        placeholder: 'A00000000',
    };
    const checkIfDocumentIdIsValid = (documentId) => {
        setIsNifDuplicated(false);
        isLegalPersonNumberValid(documentId)
            .then((res) => {
            if (res.status === 200) {
                setNifIsInvalid(false);
                checkUsernameExists({ username: documentId })
                    .then(({ status }) => {
                    switch (status) {
                        case 200:
                            setIsNifDuplicated(true);
                            break;
                        case 404:
                            setIsNifDuplicated(false);
                            break;
                        default:
                            break;
                    }
                })
                    .catch(() => setIsNifDuplicated(false));
            }
            else {
                setNifIsInvalid(true);
            }
        })
            .catch(() => {
            setNifIsInvalid(true);
        });
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const onChange = (value, name) => {
        if (name == 'document_id') {
            checkIfDocumentIdIsValid(value);
        }
        const response = {
            name: name,
            value: value,
        };
        saveResponse(response);
        updateResponse(response);
    };
    return (_jsxs("div", Object.assign({ className: "registration-page__content" }, { children: [_jsx("h2", Object.assign({ className: "registration-page__content-subtitle", "data-testid": 'legal-data-company-page-title' }, { children: t('texts.registrationPageTexts.legalDataCompanyScreen.subtitle').toUpperCase() })), _jsxs("div", Object.assign({ className: "registration-page__content-body" }, { children: [_jsx("div", { className: "registration-page__content-information", dangerouslySetInnerHTML: { __html: t('texts.registrationPageTexts.legalDataCompanyScreen.description') } }), _jsx("div", Object.assign({ className: "registration-page__content-question" }, { children: _jsx(RegistrationTextQuestion, { id: 'business_name', content: businessNameTextQuestion, onChange: onChange, fieldName: 'business_name', maxLength: 150 }) })), _jsxs("div", Object.assign({ className: "registration-page__content-question" }, { children: [_jsx(RegistrationTextQuestion, { id: 'document_id', content: documentIdTextQuestion, onChange: onChange, fieldName: 'document_id', error: nifIsInvalid || isNifDuplicated }), nifIsInvalid && (_jsx("div", Object.assign({ id: "nif-is-invalid", className: "registration-page__content-error_message", "data-testid": "nif-is-invalid" }, { children: t('texts.registrationPageTexts.validationMessages.invalidFiscalNumber') }))), isNifDuplicated && (_jsx("div", { id: "nif-is-duplicated", className: "registration-page__content-error_message", "data-testid": "nif-is-duplicated", dangerouslySetInnerHTML: { __html: t('texts.registrationPageTexts.validationMessages.duplicatedFiscalNumber') } }))] }))] }))] })));
};
export default LegalDataCompany;
