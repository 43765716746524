import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
const Dropdown = ({ label, placeholder, questionValues, onChangeFn, onChangeMultipleFn, onInputChangeFn, fieldName, isMultiSelect, isOptionDisable, }) => {
    const { t } = useTranslation();
    const [questionOptions, setQuestionOptions] = useState([]);
    useEffect(() => {
        setQuestionOptions(questionValues);
    }, [label, questionValues]);
    const options = questionOptions === null || questionOptions === void 0 ? void 0 : questionOptions.map((option) => {
        return { value: t(option.value), label: t(option.text) };
    });
    const handleOnSelectSingle = (newValue) => {
        onChangeFn(newValue.value, fieldName ? fieldName : null);
    };
    const handleOnSelectMultiple = (newValues) => {
        onChangeMultipleFn(newValues.map((newValue) => newValue.value));
    };
    const handleOnInputChange = (inputValue) => {
        onInputChangeFn === null || onInputChangeFn === void 0 ? void 0 : onInputChangeFn(inputValue);
    };
    return (_jsxs("div", Object.assign({ className: "dropdown__container", "data-testid": "dropdown-container" }, { children: [_jsx("label", Object.assign({ className: "dropdown__label", "data-testid": "dropdown-label" }, { children: t(label) })), _jsx(Select, { options: options, onInputChange: handleOnInputChange, placeholder: t(placeholder), onChange: isMultiSelect ? handleOnSelectMultiple : handleOnSelectSingle, classNamePrefix: "dropdown__element", menuPlacement: "auto", menuShouldScrollIntoView: true, menuPortalTarget: document.body, isMulti: isMultiSelect, isOptionDisabled: () => isOptionDisable })] })));
};
export default Dropdown;
