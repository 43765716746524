import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
const MultiContentBinaryQuestion = ({ handleOnClick, content, selectedValue }) => {
    const { t } = useTranslation();
    const getResponse = (q) => {
        return {
            contentId: content.id,
            contentValue: q.value,
            valueType: content.type,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
        };
    };
    function isSelected(q) {
        return (selectedValue && selectedValue.contentValue === q.value);
    }
    return (_jsxs("div", Object.assign({ className: "question__answers-binary", "data-testid": "binary-type-question" }, { children: [_jsx("label", Object.assign({ className: "question__answers-binary-label" }, { children: t(content.label) })), _jsx("div", Object.assign({ className: "question__answers-binary-buttons" }, { children: content.questionValues.map((q) => {
                    return (_jsx("button", Object.assign({ "data-testid": "binary-button", onClick: () => handleOnClick(getResponse(q)), className: `button--multi-content-binary ${isSelected(q) ? 'button--multi-content-binary--selected' : ''}` }, { children: t(q.text).toUpperCase() }), q.order));
                }) }))] })));
};
export default MultiContentBinaryQuestion;
