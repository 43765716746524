import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
export const ProtectedRoute = () => {
    const { token } = useAuth();
    if (!token) {
        return _jsx(Navigate, { to: "/login" });
    }
    return _jsx(Outlet, {});
};
