var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { log } from '../utils/logger';
export const API_BASE_URL = window._env_.REACT_APP_SIGNO_API_URL;
// idcompany: 4881
// User endpoints
export const postUserLogin = (body) => {
    log.debug(`Calling API to login user ${body.username}`);
    return axios.post(`${API_BASE_URL}/User/login`, body);
};
export const postUserRegistration = (body) => {
    log.debug(`Calling API to register user ${body.rlegal_document_id}`);
    return axios.post(`${API_BASE_URL}/User/registro`, body);
};
export const postValidatePassword = (password) => {
    log.debug('Calling API to validate Password');
    return axios.post(`${API_BASE_URL}/User/registro/password/validate`, { password: password });
};
export const postUserResetPassword = (body) => {
    log.debug(`Calling API to reset password for user ${body.username}`);
    return axios.post(`${API_BASE_URL}/User/reset-password`, body);
};
export const getCompanyInfo = (companyId) => {
    log.debug(`Calling API to fetch company info for company ID ${companyId}`);
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/Company/${companyId}`);
};
export const checkUsernameExists = (body) => {
    log.debug(`Calling API to check if user ${body.username} exists.`);
    return axios.post(`${API_BASE_URL}/User/username-exists`, body);
};
// Collective endpoint.
export const getCollectiveId = (hash) => {
    log.debug('Calling API to get collective Id from the hash in the registration URL');
    return axios.get(`${API_BASE_URL}/Collective?hash=${hash}`);
};
// Helper endpoints (currently used in Registration form)
export const getActivities = () => {
    log.debug('Calling API to get all business activities to be displayed in the registration form');
    return axios.get(`${API_BASE_URL}/Activity`);
};
export const isLegalPersonNumberValid = (documentId) => {
    log.debug(`Calling API to validate Fiscal Number ${documentId}`);
    return axios.post(`${API_BASE_URL}/LegalDocument/legal-person/validate`, { documentId: documentId });
};
export const isNaturalPersonNumberValid = (documentId) => {
    log.debug(`Calling API to validate Identity Number ${documentId}`);
    return axios.post(`${API_BASE_URL}/LegalDocument/natural-person/validate`, { documentId: documentId });
};
// Form endpoints
export const postNewUserForm = (companyId, serviceId) => {
    log.debug(`Calling API to create new userForm for company ID ${companyId}`);
    return axios.post(`${API_BASE_URL}/ServiceFormFilling/create?companyId=${companyId}&serviceId=${serviceId}`);
};
export const getUserFormInfo = (companyId, serviceId) => {
    log.debug(`Calling API to get existing userForm info for company ID ${companyId}`);
    return axios.get(`${API_BASE_URL}/ServiceFormFilling?companyId=${companyId}&serviceId=${serviceId}`);
};
export const getUserFormResponses = (userFormId) => {
    log.debug(`Calling API to get form responses for userFormId ${userFormId}`);
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/form-responses`);
};
export const putUserFormResponses = (responses, userFormId) => {
    log.debug(`Updating in Database form id ${userFormId} with the following responses ===> `, responses);
    return axios.put(`${API_BASE_URL}/ServiceFormFilling/${userFormId}`, { responses: responses });
};
export const putFormResponseHistory = (formResponseHistoryRequest) => {
    log.debug(`Updating or creating in Database(formResponseHistory) form id ${formResponseHistoryRequest[0].serviceFormFillingId} with the following response ===> `, formResponseHistoryRequest);
    return axios.put(`${API_BASE_URL}/ServiceFormFilling/${formResponseHistoryRequest[0].serviceFormFillingId}/form-response-history`, formResponseHistoryRequest);
};
export const getUserFormResponseSummary = (serviceFormFillingId) => {
    log.debug(`Calling API to get form responses history for service form filling ${serviceFormFillingId}`);
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${serviceFormFillingId}/form-response-summary`);
};
export const postUserForm = (responses, userFormId) => {
    log.debug(`Submitting in Database form id ${userFormId} with the following responses ===> `, responses);
    return axios.post(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/completed`, { responses: responses });
};
// Documentation endpoints
export const getIsDocumentationGenerated = (userFormId) => {
    log.debug('Calling API to check if documentation generated');
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/is-documentation-generated`);
};
export const fetchLatestGeneratedPDDDocumentation = (companyId, serviceId = '1') => {
    log.debug(`Calling API to fetch latest generated PDD documentation for company ID ${companyId}`);
    return axios.post(`${API_BASE_URL}/Document/latest?companyId=${companyId}&serviceId=${serviceId}`);
};
export const fetchLatestGeneratedCPSDocumentation = (companyId, serviceId = '1') => {
    log.debug(`Calling API to fetch latest generated CPS documentation for company ID ${companyId}`);
    return axios.post(`${API_BASE_URL}/Document/prestador/latest?companyId=${companyId}&serviceId=${serviceId}`);
};
// EIPD endpoints
export const calculateEipd = (userFormId) => {
    log.debug('Calling API to Calculate EIPD');
    axios
        .post(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/eipd`)
        .then((res) => log.debug('Calling API to Calculate EIPD returned ' + res.status))
        .catch(() => log.debug('Calling API to Calculate EIPD failed'));
};
export const getEipdResult = (userFormId) => {
    log.debug('Calling API to get EIPD result');
    return axios
        .get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/eipd`)
        .then((res) => {
        return res.data;
    })
        .catch(() => {
        return null;
    });
};
export const getPendingEipdOTs = (userFormId) => {
    log.debug('Calling API to get pending Operaciones de Tratamientos');
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/pending-eipd-ots`);
};
export const fetchGeneratedActivos = (userFormId) => {
    log.debug('Calling API to get generated activos');
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/generated-activos`);
};
export const putOt = (userFormId, otId, body) => {
    log.debug(`Calling API to save completed for serviceFormFillingId ${userFormId} and OT ${otId} using putOt`);
    return axios.put(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/ot/${otId}`, body);
};
// DPD endpoint
export const getIsDpdMandatoryResult = (userFormId) => {
    log.debug(`Calling API to check if DPD is mandatory for serviceFormFillingId ${userFormId}`);
    return axios.get(`${API_BASE_URL}/ServiceFormFilling/${userFormId}/isDpdMandatory`);
};
// Location endpoint
export const getLocationsByPostalCode = (postalCode) => __awaiter(void 0, void 0, void 0, function* () {
    log.debug(`Calling API to check if postal code ${postalCode} have locations.`);
    return yield axios.get(`${API_BASE_URL}/Location/${postalCode}`);
});
// Client endpoint
export const postClient = (body) => {
    log.debug(`Calling API to get token client ${body.clientSecret}`);
    return axios.post(`${API_BASE_URL}/Auth/Client`, body);
};
