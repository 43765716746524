import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import EipdContext from '../../../../../contexts/EipdContext';
import { useTranslation } from 'react-i18next';
const OtItem = ({ index, ot, goToComplete }) => {
    const { t } = useTranslation();
    const { saveCurrentOt } = useContext(EipdContext);
    const goToCompleteOtInformation = () => {
        saveCurrentOt(ot);
        goToComplete();
    };
    return (_jsxs("div", Object.assign({ className: "card" }, { children: [_jsxs("div", Object.assign({ className: "card-header" }, { children: [_jsx("div", Object.assign({ className: "card-header__text", "data-testid": `ot-card-${index}` }, { children: ot.title })), _jsxs("button", Object.assign({ className: "button--complete", "data-testid": `complete-button-${index}`, onClick: goToCompleteOtInformation }, { children: [_jsx("div", Object.assign({ className: "button--complete__text" }, { children: t('texts.iterativeOtsTexts.complete').toUpperCase() })), _jsx("i", { className: "bi bi-arrow-right-circle" })] }))] })), _jsx("div", Object.assign({ className: "card-body" }, { children: _jsx("p", Object.assign({ className: "card-text" }, { children: ot.purpose })) }))] })));
};
export default OtItem;
