import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import PrimaryOutlineButton from '../Buttons/PrimaryOutlineButton';
const AlertDialog = ({ alertContent, closeAlertDialog, handleAlertActionFn }) => {
    const [isActionBtnDisabled, setIsActionBtnDisabled] = useState(false);
    const handleClick = () => {
        setIsActionBtnDisabled(true);
        handleAlertActionFn();
    };
    return (_jsx("div", Object.assign({ className: "modal fade show", style: { display: 'block' }, id: "alert-dialog" }, { children: _jsx("div", Object.assign({ className: "modal-dialog modal-dialog-centered" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsxs("div", Object.assign({ className: "modal-header" }, { children: [_jsx("h4", Object.assign({ className: "modal-title" }, { children: alertContent.title })), _jsx("button", { type: "button", className: "btn-close", onClick: closeAlertDialog, "aria-label": "Close" })] })), _jsxs("div", Object.assign({ className: "modal-body" }, { children: [_jsx("p", { children: alertContent.body }), _jsx("p", { children: alertContent === null || alertContent === void 0 ? void 0 : alertContent.subBody })] })), _jsxs("div", Object.assign({ className: "modal-footer" }, { children: [_jsx(PrimaryOutlineButton, { testId: 'modal-button', text: alertContent === null || alertContent === void 0 ? void 0 : alertContent.closeButton, onClickFn: closeAlertDialog, enabled: true, submitButton: false }), _jsx(PrimaryButton, { testId: 'modal-button', text: alertContent === null || alertContent === void 0 ? void 0 : alertContent.actionButton.toUpperCase(), onClickFn: handleClick, isDisabled: isActionBtnDisabled })] }))] })) })) })));
};
export default AlertDialog;
