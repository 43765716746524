import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchGeneratedActivos, getPendingEipdOTs } from '../../../../../api/signoAPI';
import EipdContext from '../../../../../contexts/EipdContext';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import { log } from '../../../../../utils/logger';
import NextButton from '../../../../common/Buttons/NextButton';
import OtItem from './OtItem';
import { scrollToTop } from '../../../../../utils/common-utils';
const IterativeOts = ({ content, goToNextContent }) => {
    const { t } = useTranslation();
    const [pendingOts, setPendingOts] = useState([]);
    const [hasCompletedAllOts, setHasCompletedAllOts] = useState(false);
    const { getGeneratedActivos, saveGeneratedActivos } = useContext(EipdContext);
    const { getUserFormId } = useContext(FormResponseContext);
    useEffect(() => {
        getPendingEipdOTs(getUserFormId())
            .then(({ data }) => {
            setPendingOts(data);
            if (data.length > 0) {
                setHasCompletedAllOts(false);
            }
            else {
                setHasCompletedAllOts(true);
            }
        })
            .catch((error) => log.debug(error));
        if (getGeneratedActivos() === null) {
            fetchGeneratedActivos(getUserFormId())
                .then(({ data }) => {
                saveGeneratedActivos(data);
            })
                .catch((e) => log.debug(e));
        }
    }, [getUserFormId, getGeneratedActivos, saveGeneratedActivos]);
    const getSectionTitle = (pendingActions) => {
        //TODO esto de poner pendiente como literal es una chapuza choca con los idiomas
        return (pendingActions === null || pendingActions === void 0 ? void 0 : pendingActions.length) === 1 ? t('texts.iterativeOtsTexts.subtitle').replace(/\bpendientes\b/, 'pendiente') : t('texts.iterativeOtsTexts.subtitle');
    };
    return (_jsxs("div", Object.assign({ className: "iterative-ots-page" }, { children: [_jsx("h1", Object.assign({ className: "iterative-ots-page__title" }, { children: t(content.title) })), _jsxs("div", Object.assign({ className: "iterative-ots-page__body" }, { children: [(pendingOts === null || pendingOts === void 0 ? void 0 : pendingOts.length) > 0 && (_jsxs("h3", Object.assign({ className: "iterative-ots-page__section-title", "data-testid": "iterative-ots-section-title" }, { children: [pendingOts === null || pendingOts === void 0 ? void 0 : pendingOts.length, " ", getSectionTitle(pendingOts)] }))), hasCompletedAllOts ? (_jsxs("div", Object.assign({ className: "alert-message alert alert-success", role: "alert", "data-testid": "no-pending-actions-success-messsage" }, { children: [_jsx("i", { className: "bi bi-check" }), _jsx("span", { children: t('texts.iterativeOtsTexts.successMessage') })] }))) : (pendingOts && (_jsx("div", Object.assign({ className: "iterative-items", "data-testid": "iterative-items" }, { children: pendingOts.map((ot, index) => {
                            return _jsx(OtItem, { ot: ot, index: index, goToComplete: () => { goToNextContent(content.completeLinkTo); scrollToTop(); } }, index);
                        }) }))))] })), _jsx("div", Object.assign({ className: "iterative-ots-page__next-action" }, { children: _jsx(NextButton, { onClickFn: () => { goToNextContent(content.nextButton.linkTo); scrollToTop(); }, text: content.nextButton.title, enabled: hasCompletedAllOts }) }))] })));
};
export default IterativeOts;
