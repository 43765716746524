import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { ProtectedClientRoute } from './ProtectedClientRoute';
import Login from '../components/pages/Login/Login';
import Form from '../components/pages/Form/Form';
import dataJson from '../data/formModel.json';
import { useAuth } from '../contexts/AuthContext';
import Documentation from '../components/pages/Documentation/Documentation';
import Registration from '../components/pages/Registration/Registration';
import ResetPassword from '../components/pages/ResetPassword/ResetPassword';
import ErrorPage, { ErrorEnum } from '../components/pages/Error/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { useMaintenance } from '../contexts/MaintenanceContext';
import MaintenancePage from '../components/pages/Manteinance/MaintenancePage';
import AuthenticatedOnlyForm from '../components/common/Containers/wrappers/AuthenticatedOnlyForm';
import Storybook from '../components/common/storybook/storybook';
const Routes = () => {
    const { token } = useAuth();
    const { isDeploying } = useMaintenance();
    const data = dataJson;
    const routesForPublic = [
        {
            path: '*',
            element: _jsx(ErrorPage, { error: ErrorEnum.PAGE_NOT_FOUND }),
        },
        {
            path: '/maintenance-test-it',
            element: _jsx(MaintenancePage, {}),
        },
    ];
    const routesForMaintenance = [
        {
            path: '/',
            element: _jsx(MaintenancePage, {}),
        },
        {
            path: '*',
            element: _jsx(Navigate, { to: "/", replace: true }),
        },
    ];
    const routesForAuthenticatedOnly = [
        {
            path: '/',
            element: _jsx(ProtectedRoute, {}),
            children: [
                {
                    path: '/',
                    element: (_jsx(AuthenticatedOnlyForm, { children: _jsx(Form, {}) })),
                },
                {
                    path: '/form',
                    element: (_jsx(AuthenticatedOnlyForm, { children: _jsx(Form, {}) })),
                },
                {
                    path: '/documentation',
                    element: (_jsx(AuthenticatedOnlyForm, { children: _jsx(Documentation, {}) })),
                },
                /* {
                  path: '/tasks',
                  element: (
                    <AuthenticatedOnly>
                      <TasksPage />
                    </AuthenticatedOnly>
                  ),
                  children: [
                    {
                      path: ':idTask',
                      element: (
                        <AuthenticatedOnly>
                          <TasksPage />
                        </AuthenticatedOnly>
                      ),
                    },
                  ],
                },
                {
                  path: '/managements',
                  element: (
                    <AuthenticatedOnly>
                      <Managements />
                    </AuthenticatedOnly>
                  ),
                },
                
                {
                  path: 'form/summary-form',
                  element: (
                    <AuthenticatedOnlyForm>
                      <Summary />
                    </AuthenticatedOnlyForm>
                  ),
                },
                {
                  path: 'summary-form-pdf',
                  element: (
                    <AuthenticatedOnlyForm>
                      <SummaryFormPdf />
                    </AuthenticatedOnlyForm>
                  ),
                }, */
            ],
            errorElement: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
        {
            path: '*',
            element: _jsx(Navigate, { to: "/" }),
        },
    ];
    const routesForNotAuthenticatedOnly = [
        {
            path: '/',
            element: _jsx(ProtectedClientRoute, {}),
            children: [
                {
                    path: '/',
                    element: (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(Login, {}) }))),
                },
                {
                    path: '/login',
                    element: (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(Login, {}) }))),
                },
                {
                    path: '/registro',
                    element: (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(Registration, {}) }))),
                },
                {
                    path: '/reseteo',
                    element: (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(ResetPassword, {}) }))),
                },
                {
                    path: '/storybook',
                    element: (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(Storybook, {}) }))),
                },
            ],
            errorElement: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
        {
            path: '*',
            element: _jsx(Navigate, { to: "/", replace: true }),
        },
    ];
    const routesWithoutDeployment = [...routesForPublic, ...(!token ? routesForNotAuthenticatedOnly : []), ...routesForAuthenticatedOnly];
    const routeWithDeployment = [...routesForMaintenance];
    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter(isDeploying ? routeWithDeployment : routesWithoutDeployment);
    return _jsx(RouterProvider, { router: router });
};
export default Routes;
