var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { showSnackbar } from './snackbarSrv';
import i18n from '../i18n';
export const API_BASE_URL = window._env_.REACT_APP_SIGNO_API_URL;
let isSnackbarActive = false;
export const getCompanyIdFromToken = (authToken) => {
    const decodedToken = jwt_decode(authToken);
    return decodedToken['companyId'];
};
const getExpirationDateFromToken = (authToken) => {
    const decodedToken = jwt_decode(authToken);
    return decodedToken['exp'];
};
const isTokenValid = (authToken) => {
    return getExpirationDateFromToken(authToken) > Date.now().toString();
};
// Request interceptor for API calls
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('client-token') || localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    Promise.reject(error);
});
// Response interceptor for API calls
axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const { data } = error.response;
        const message = (data === null || data === void 0 ? void 0 : data.message) || i18n.t('common.message.error');
        const originalRequest = error.config;
        const token = localStorage.getItem('token');
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && token && !isTokenValid(token) && !originalRequest._retry) {
            originalRequest._retry = true;
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
            window.location.reload();
        }
        if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 400 && !isSnackbarActive) {
            isSnackbarActive = true;
            showSnackbar(message, 'error');
            setTimeout(() => {
                isSnackbarActive = false;
            }, 1000);
        }
        return Promise.reject(error);
    });
});
