import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SubsectionItem from './SubsectionItem';
import VerticalLine, { VerticalLinePositionEnum } from './VerticalLine';
import { useTranslation } from 'react-i18next';
const SectionItem = ({ menuSection, currentSectionId, completedSectionsIds }) => {
    const { t } = useTranslation();
    const hasSubsections = menuSection.subsections.length > 0;
    const isCurrentSection = menuSection.id === currentSectionId;
    const isCurrentSubsection = menuSection.subsections.filter((subsection) => subsection.id == currentSectionId).length > 0;
    const isPreviousSectionCompleted = () => {
        return completedSectionsIds.includes(menuSection.previousSectionId);
    };
    const renderSubsections = () => {
        return menuSection.subsections.map((subsection) => {
            return _jsx(SubsectionItem, { subsection: subsection, currentSectionId: currentSectionId }, subsection.id);
        });
    };
    return (_jsxs("div", Object.assign({ "data-testid": 'section' }, { children: [_jsxs("div", Object.assign({ className: "section-item" }, { children: [_jsxs("div", Object.assign({ className: "section-item__progress" }, { children: [_jsx(VerticalLine, { position: VerticalLinePositionEnum.UP, transparent: menuSection.isFirstSection, previousSectionCompleted: isPreviousSectionCompleted() }), _jsx("i", { className: `bi
           ${menuSection.isCompleted ? 'bi-check-circle-fill' : isCurrentSection || isCurrentSubsection ? 'bi-check-circle' : 'bi-circle'}
            section-item__icon` }), _jsx(VerticalLine, { position: VerticalLinePositionEnum.DOWN, transparent: menuSection.isLastSection && !((hasSubsections && isCurrentSection) || isCurrentSubsection), sectionCompleted: menuSection.isCompleted })] })), _jsx("p", Object.assign({ "data-testid": "menu-section-title", className: `section-item__text
          ${isCurrentSection || isCurrentSubsection ? 'section-item__text--active' : 'section-item__text'}
          ${menuSection.isCompleted ? 'section-item__text--completed' : 'section-item__text'}` }, { children: t(menuSection.sectionTitle) }))] })), _jsx("div", Object.assign({ className: menuSection.isLastSection ? 'subsection-items--end' : 'subsection-items' }, { children: ((isCurrentSection && hasSubsections) || isCurrentSubsection) && renderSubsections() }))] })));
};
export default SectionItem;
