import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { InfoContentSubtype } from '../../../../../types/FormModelTypes';
import InfoScreen from './InfoScreen';
import IntroAndEndScreen from './IntroAndEndScreen';
import OtIntroScreen from './OtIntroScreen';
import UserContext from '../../../../../contexts/UserContext';
const InfoContainer = ({ content, goToNextContent }) => {
    var _a;
    const { getPersonalization, getCheckPersonalizationApiCalled } = useContext(UserContext);
    const renderScreen = () => {
        switch (content.subtype) {
            case InfoContentSubtype.INTRO_AND_END:
                return _jsx(IntroAndEndScreen, { content: content, goToNextContent: goToNextContent });
            case InfoContentSubtype.OT_INTRO:
                return _jsx(OtIntroScreen, { content: content, goToNextContent: goToNextContent });
            default:
                return _jsx(InfoScreen, { content: content, goToNextContent: goToNextContent });
        }
    };
    const getImageUrl = () => {
        const personalization = getPersonalization();
        if (!(personalization === null || personalization === void 0 ? void 0 : personalization.formImages)) {
            return null;
        }
        const formImage = personalization.formImages.find((formImage) => formImage.contentId === content.id);
        return formImage && formImage.urlImage;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "info-container", "data-testid": "info-component" }, { children: [content.subtype !== InfoContentSubtype.OT_INTRO && (_jsx("div", Object.assign({ className: "card__image" }, { children: _jsx("img", { alt: "Intro card image", src: (_a = getImageUrl()) !== null && _a !== void 0 ? _a : content.imageUrl }) }))), renderScreen()] })) }));
};
export default InfoContainer;
