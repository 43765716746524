import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images, { taskType } from '../../../../utils/images';
export default function BtnNotifications({ state }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs("button", Object.assign({ className: `notifications-main-btn ${state}`, type: "button" }, { children: [_jsxs("div", Object.assign({ className: "notifications-main-btn_description" }, { children: [_jsx("div", Object.assign({ className: "img" }, { children: _jsx("img", { src: taskType[1], alt: "Tipo tarea" }) })), _jsx("p", { dangerouslySetInnerHTML: { __html: 'Tienes 3 tareas pendientes de <strong>firma</strong>' } })] })), _jsxs("div", Object.assign({ className: "notifications-main-btn_data" }, { children: [_jsx("span", Object.assign({ className: "time" }, { children: "25 minutos" })), _jsx(Tooltip, Object.assign({ title: t('tasks.btnOptions'), placement: "top" }, { children: _jsx(IconButton, Object.assign({ size: "large", id: "basic-button", "aria-controls": openMenu ? 'basic-menu' : undefined, "aria-haspopup": "true", "aria-expanded": openMenu ? 'true' : undefined, onClick: handleClick, "aria-label": t('tasks.btnOptions'), color: "primary" }, { children: _jsx(MoreHorizIcon, { fontSize: "inherit" }) })) })), _jsxs(Menu, Object.assign({ id: "basic-menu", anchorEl: anchorEl, open: openMenu, onClose: handleClose, MenuListProps: {
                            'aria-labelledby': 'basic-button',
                        } }, { children: [_jsxs(MenuItem, Object.assign({ onClick: () => {
                                    handleClose();
                                } }, { children: [_jsx(ListItemIcon, { children: _jsx(VisibilityOffOutlinedIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: t('notifications.checkRead') })] })), _jsxs(MenuItem, Object.assign({ onClick: () => {
                                    handleClose();
                                } }, { children: [_jsx(ListItemIcon, { children: _jsx("img", { src: images.delete, alt: "" }) }), _jsx(ListItemText, { children: t('notifications.delete') })] }))] }))] }))] })));
}
