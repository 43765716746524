import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import EipdContext from '../../../../../contexts/EipdContext';
import DropdownQuestion from './DropdownQuestion';
const DropdownActivosQuestion = ({ content, handleOnChange, handleOnChangeMultiple, isOptionDisable }) => {
    const { getGeneratedActivos } = useContext(EipdContext);
    const [questionValues, setQuestionValues] = useState([]);
    useEffect(() => {
        var _a;
        const activosValues = (_a = getGeneratedActivos()) === null || _a === void 0 ? void 0 : _a.map((activo, index) => {
            return { order: index, text: activo.name, value: activo.id.toString() };
        });
        setQuestionValues(activosValues);
    }, [getGeneratedActivos]);
    return (_jsx(DropdownQuestion, { content: Object.assign({}, content, { questionValues: questionValues }), handleOnChange: handleOnChange, handleOnChangeMultiple: handleOnChangeMultiple, isOptionDisable: isOptionDisable }));
};
export default DropdownActivosQuestion;
