import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
const ClientContext = createContext(null);
const ClientProvider = ({ children }) => {
    const [clientToken, setClientToken_] = useState(localStorage.getItem('client-token'));
    const removeClientToken = () => setClientToken(null);
    const setClientToken = (newToken) => {
        setClientToken_(newToken);
    };
    useEffect(() => {
        if (clientToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + clientToken;
            localStorage.setItem('client-token', clientToken);
        }
    }, [clientToken]);
    const contextValue = useMemo(() => ({
        clientToken,
        setClientToken,
        removeClientToken
    }), [clientToken]);
    // Provide the authentication context to the children components
    return _jsx(ClientContext.Provider, Object.assign({ value: contextValue }, { children: children }));
};
export const useClientAuth = () => {
    return useContext(ClientContext);
};
export default ClientProvider;
