import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import FormResponseContext from './FormResponseContext';
import { log } from '../utils/logger';
export var FormStatus;
(function (FormStatus) {
    FormStatus["NOT_STARTED"] = "not started";
    FormStatus["IN_PROGRESS"] = "in progress";
    FormStatus["COMPLETED"] = "completed";
})(FormStatus || (FormStatus = {}));
export const FormProgressContext = createContext(null);
export const FormProgressContextProvider = ({ children, sectionId = 'formStart', formStatus = FormStatus.NOT_STARTED, sectionsData, }) => {
    const [currentSectionId, setCurrentSectionId] = useState(sectionId);
    const [editableQuestionId, setEditableQuestionId] = useState(null);
    const [formProgressStatus, setFormProgressStatus] = useState(formStatus);
    const [completedSectionsIds, setCompletedSectionsIds] = useState([]);
    const { getFormCompletionDate, formResponses } = useContext(FormResponseContext);
    const getCurrentSectionId = () => {
        return currentSectionId;
    };
    const saveCurrentSectionId = (sectionId) => {
        setCurrentSectionId(sectionId);
    };
    const getFormProgressStatus = () => {
        return formProgressStatus;
    };
    const getEditableQuestionId = () => {
        return editableQuestionId;
    };
    const saveEditableQuestionId = (contentId) => {
        setEditableQuestionId(contentId);
    };
    const saveFormProgressStatus = (status) => {
        setFormProgressStatus(status);
    };
    const getCompletedSectionsIds = () => {
        return completedSectionsIds;
    };
    const saveCompletedSectionsIds = (sections) => {
        setCompletedSectionsIds(sections);
    };
    const getSectionsData = () => {
        return sectionsData;
    };
    const value = {
        getSectionsData,
        getCurrentSectionId,
        saveCurrentSectionId,
        getFormProgressStatus,
        saveFormProgressStatus,
        getCompletedSectionsIds,
        saveCompletedSectionsIds,
        getEditableQuestionId,
        saveEditableQuestionId
    };
    useEffect(() => {
        var _a;
        const status = getFormCompletionDate() !== undefined && getFormCompletionDate() !== null
            ? FormStatus.COMPLETED
            : (formResponses === null || formResponses === void 0 ? void 0 : formResponses.length) > 0
                ? FormStatus.IN_PROGRESS
                : FormStatus.NOT_STARTED;
        setFormProgressStatus(status);
        log.debug('form progress use effect.', {
            status: status,
            formCompletionDate: getFormCompletionDate(),
            formResponses: formResponses === null || formResponses === void 0 ? void 0 : formResponses.length,
            formResponsesArray: formResponses,
            completedSectionsIds: completedSectionsIds,
            currentSectionId: currentSectionId,
        });
        if (completedSectionsIds.length == 0 && currentSectionId == 'formStart' && (formResponses === null || formResponses === void 0 ? void 0 : formResponses.length) > 0) {
            log.debug('computing completed sections');
            // Get all the form sections ids and their parent id in case of being subsections
            const sectionsIds = sectionsData.map((section) => {
                return { id: section.id, parent: section.parent };
            });
            // Get all the so far completed responses ids
            const responsesIds = [...new Set(formResponses.map((r) => r.contentId.split('-')[0]))];
            // Transform the so far completed responses ids as sections ids, with their parent id in case of being subsections
            let previouslyCompletedSectionsIds = [];
            responsesIds.map((responseId) => {
                sectionsIds.map((section) => {
                    if (section.id === responseId && section.parent !== undefined)
                        previouslyCompletedSectionsIds.push(responseId, section.parent);
                    else
                        previouslyCompletedSectionsIds.push(responseId);
                });
            });
            // TODO: revisarse bien esto
            // remove duplicates and order the array previouslyCompletedSectionsIds
            previouslyCompletedSectionsIds = [...new Set(previouslyCompletedSectionsIds)];
            previouslyCompletedSectionsIds = previouslyCompletedSectionsIds.sort((a, b) => {
                if (parseFloat(a.match(/\d+/)[0]) !== parseFloat(b.match(/\d+/)[0])) {
                    return parseFloat(a.match(/\d+/)[0]) - parseFloat(b.match(/\d+/)[0]); // Compare the numeric parts
                }
                else {
                    return a.length - b.length; // If numeric parts are equal, compare based on string length
                }
            });
            if (status != FormStatus.COMPLETED) {
                const idOfLastSectionInProgress = previouslyCompletedSectionsIds[previouslyCompletedSectionsIds.length - 1];
                setCurrentSectionId(idOfLastSectionInProgress);
                const lastSectionInProgressParent = (_a = sectionsIds.find((section) => section.id === idOfLastSectionInProgress)) === null || _a === void 0 ? void 0 : _a.parent;
                previouslyCompletedSectionsIds = previouslyCompletedSectionsIds.filter((sectionId) => {
                    if (lastSectionInProgressParent !== undefined)
                        return sectionId !== idOfLastSectionInProgress && sectionId !== lastSectionInProgressParent;
                    else
                        return sectionId !== idOfLastSectionInProgress;
                });
            }
            setCompletedSectionsIds(previouslyCompletedSectionsIds);
        }
    }, [completedSectionsIds, currentSectionId, formResponses, getFormCompletionDate, sectionsData]);
    return _jsx(FormProgressContext.Provider, Object.assign({ value: value }, { children: children }));
};
export default FormProgressContext;
