import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationBinaryQuestion from './Questions/RegistrationBinaryQuestion';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
const LegalData = ({ setIsSelfEmployed, saveResponse, handleNextButton }) => {
    const { t } = useTranslation();
    const [responses, setResponses] = useState([]);
    useEffect(() => {
        if ((responses === null || responses === void 0 ? void 0 : responses.length) == 1) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton]);
    const isSelfEmployeeBinaryQuestion = {
        description: '¿Estás activando Signo para una empresa o para una persona trabajadora autónoma?',
        questionValues: [
            {
                value: '0',
                text: 'Empresa',
            },
            {
                value: '1',
                text: 'Persona trabajadora autónoma',
            },
        ],
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const onClick = (questionValue) => {
        const response = {
            name: 'self_employed',
            value: questionValue === '1',
        };
        setIsSelfEmployed(questionValue === '1');
        saveResponse(response);
        updateResponse(response);
    };
    return (_jsxs("div", Object.assign({ className: "registration-page__content" }, { children: [_jsx("h2", Object.assign({ className: "registration-page__content-subtitle", "data-testid": 'legal-data-page-title' }, { children: t('texts.registrationPageTexts.legalDataScreen.subtitle').toUpperCase() })), _jsx("div", Object.assign({ className: "registration-page__content-body" }, { children: _jsx("div", Object.assign({ className: "registration-page__content-question multi-content" }, { children: _jsx(RegistrationBinaryQuestion, { content: isSelfEmployeeBinaryQuestion, onClick: onClick }) })) }))] })));
};
export default LegalData;
