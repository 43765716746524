import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../../types/FormModelTypes';
import ContextualHelpModal from '../../../common/Modals/ContextualHelp/ContextualHelpModal';
import RegistrationBinaryQuestion from './Questions/RegistrationBinaryQuestion';
const Intro = ({ setIsPublicAdmin, handleNextButton }) => {
    const { t } = useTranslation();
    const isPublicAdminBinaryQuestion = {
        description: '¿Tu entidad es considerada una entidad pública?',
        questionValues: [
            {
                value: '0',
                text: t('common.no'),
            },
            {
                value: '1',
                text: t('common.yes'),
            },
        ],
    };
    const publicEntityInfo = {
        title: t('texts.registrationPageTexts.introScreen.infoModal.title'),
        description: t('texts.registrationPageTexts.introScreen.infoModal.description'),
        buttonText: t('texts.registrationPageTexts.introScreen.infoModal.buttonText'),
    };
    const onClick = (questionValue) => {
        setIsPublicAdmin(questionValue === '1');
        handleNextButton(true);
    };
    return (_jsx("div", Object.assign({ className: "registration-page__content" }, { children: _jsxs("div", Object.assign({ className: "registration-page__content-body" }, { children: [_jsx("p", Object.assign({ className: "registration-page__content-description" }, { children: t('texts.registrationPageTexts.introScreen.description') })), _jsxs("div", Object.assign({ className: "registration-page__content-question multi-content" }, { children: [_jsx(RegistrationBinaryQuestion, { content: isPublicAdminBinaryQuestion, onClick: onClick }), _jsx(ContextualHelpModal, { modalContent: publicEntityInfo, contentType: ContentType.BINARY })] }))] })) })));
};
export default Intro;
