import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../../types/FormModelTypes';
import { INFO_ICON_WHITE_URL } from '../../../../utils/constants';
const ContextualHelpButton = ({ modalType, contentType }) => {
    const { t } = useTranslation();
    return (_jsxs("button", Object.assign({ type: "button", className: "contextual-help__button", "data-testid": "contextual-help-button", "data-bs-toggle": "offcanvas", "data-bs-target": `#${modalType}` }, { children: [_jsx("img", { className: "contextual-help__button-icon", alt: "Info icon", src: INFO_ICON_WHITE_URL }), _jsx("a", Object.assign({ className: "contextual-help__button-text" }, { children: contentType === ContentType.MULTIPLE
                    ? t('texts.modalsTexts.contextualHelpModal.displayButtonInMultipleQuestion')
                    : t('texts.modalsTexts.contextualHelpModal.displayButtonInBinaryQuestion') }))] })));
};
export default ContextualHelpButton;
