var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { ButtonIcon } from '../../../../../types/FormModelTypes';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import PrimaryButton from '../../../../common/Buttons/PrimaryButton';
import { INFO_ICON_BLUE_URL } from '../../../../../utils/constants';
import { getEipdResult } from '../../../../../api/signoAPI';
import EipdContext from '../../../../../contexts/EipdContext';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../utils/common-utils';
const InfoScreen = ({ content, goToNextContent }) => {
    var _a;
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    const { getFormResponseById, getUserFormId, saveFormResponse } = useContext(FormResponseContext);
    const { saveNeedEipd } = useContext(EipdContext);
    const iterativeItemsExist = content.iterativeContentParentId && ((_a = getFormResponseById(content.iterativeContentParentId).iterativeContent) === null || _a === void 0 ? void 0 : _a.length) > 0;
    useEffect(() => {
        const resolveEipdResult = () => __awaiter(void 0, void 0, void 0, function* () {
            let eipdResult = null;
            do {
                yield new Promise((r) => setTimeout(r, 2000));
                eipdResult = yield getEipdResult(getUserFormId());
            } while (eipdResult === null);
            saveNeedEipd(eipdResult);
        });
        if (content.id === 's6-c2') {
            setIsButtonDisabled(true);
            resolveEipdResult().then(() => setIsButtonDisabled(false));
        }
        else {
            setIsButtonDisabled(false);
        }
    }, [content, getUserFormId, saveNeedEipd]);
    const getTitle = () => {
        return iterativeItemsExist ? t(content.titleWhenIterativeItems) : t(content.title);
    };
    const getButtonTitle = () => {
        return content.id === 's6-c2' && isButtonDisabled ? content.nextButton.titleWhenDisabled : content.nextButton.title;
    };
    const getDescription = () => {
        const description = { __html: t(content.description) };
        const descriptionWhenIterativeItems = { __html: t(content.descriptionWhenIterativeItems) };
        return iterativeItemsExist ? descriptionWhenIterativeItems : description;
    };
    const handleClick = () => {
        var _a;
        setIsButtonDisabled(true);
        //TODO: Revisar ots iterativas
        if (!content.id.startsWith('s6') && !content.id.startsWith('formEnd')) {
            const response = {
                contentId: content.id,
                valueType: content.type,
                navBackLinkTo: content.navBackLinkTo,
                navNextLinkTo: (_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo
            };
            saveFormResponse(response, getUserFormId(), t(content.title), getCurrentSectionId());
        }
        goToNextContent(content.nextButton.linkTo);
        scrollToTop();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "card__body" }, { children: [getTitle() && _jsx("h1", Object.assign({ className: "card__header-title", "data-testid": "content-title" }, { children: getTitle() })), _jsx("div", { className: `card__header-description ${!getTitle() ? 'mt-5' : ''}`, "data-testid": "content-description", dangerouslySetInnerHTML: getDescription() }), content.infoText && (_jsxs("div", Object.assign({ className: "card__info" }, { children: [_jsx("img", { className: "card__info-icon", alt: "Info icon", src: INFO_ICON_BLUE_URL }), _jsx("p", Object.assign({ className: "card__info-text", "data-testid": "content-info-text" }, { children: t(content.infoText) }))] })))] })), _jsx("div", Object.assign({ className: "card__button" }, { children: _jsx(PrimaryButton, { text: getButtonTitle(), size: 'XL', onClickFn: handleClick, isDisabled: isButtonDisabled, testId: "primary-button-next", icon: isButtonDisabled && content.id === 's6-c2' ? ButtonIcon.SPINNER : null }) }))] }));
};
export default InfoScreen;
