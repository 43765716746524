var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import NextButton from '../../../../common/Buttons/NextButton';
import { ContentType, DropdownContentSubtype } from '../../../../../types/FormModelTypes';
import EipdContext from '../../../../../contexts/EipdContext';
import MultiContentBinaryQuestion from './Questions/MultiContentBinaryQuestion';
import DropdownQuestion from '../CommonQuestions/DropdownQuestion';
import { log } from '../../../../../utils/logger';
import DropdownActivosQuestion from '../CommonQuestions/DropdownActivosQuestion';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../utils/common-utils';
const MultiContentOTs = ({ content, goToNextContent }) => {
    const { t } = useTranslation();
    const { getCurrentOt, saveOtsResponses, removeOtsResponses, publishOtsResponses } = useContext(EipdContext);
    const [selectedOtsResponses, setSelectedOtsResponses] = useState([]);
    const [isProcalmaIsPaperStoredButtonClicked, setIsProcalmaIsPaperStoredButtonClicked] = useState(false);
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const procalmaIsPaperStoredEntity = 'procalma_is_paper_stored';
    const procalmaMandatoryEntities = ['procalma_electronic_support', 'procalma_data_location', 'procalma_figures', 'procalma_actives'];
    const procalmaOtPageIdentifier = 's6-c9';
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        const sendForm = () => __awaiter(void 0, void 0, void 0, function* () {
            const wasSuccessful = yield publishOtsResponses();
            if (!wasSuccessful) {
                showBoundary('Error publishing OT');
            }
            else {
                goToNextContent(content.nextButton.linkTo);
                scrollToTop();
            }
        });
        if (isFormCompleted) {
            sendForm().then(() => log.debug('Send form operation finished'));
        }
    }, [content.nextButton.linkTo, goToNextContent, isFormCompleted, publishOtsResponses, showBoundary]);
    const getTitle = () => {
        var _a;
        return t(content.title).replace(/\[.*?\]/g, (_a = getCurrentOt()) === null || _a === void 0 ? void 0 : _a.title);
    };
    const handleOnClick = (response) => {
        setIsProcalmaIsPaperStoredButtonClicked(true);
        const selectedResponses = [...selectedOtsResponses];
        const existingResponse = selectedResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        //TODO aqui se hace un find contra un literal "No" esto esta mal he incluido el "No" en los pricipales idiomas Europeos
        const noValuesLanguages = ['No', 'Non', 'Nein', 'Não', 'Nee', 'Nej', 'Ei', 'Nie', 'Нет', 'Όχι', 'Ez'];
        //if (!existingResponse && response.contentValue !== 'No') {
        if (!existingResponse && !noValuesLanguages.includes(response.contentValue)) {
            log.debug('Selected YES and the response had not been saved yet => saving it');
            const otEntityResponse = {
                entity: response.ocs_entity,
                otResponses: [
                    {
                        type: response.ocs_variable,
                        value: response.contentValue,
                    },
                ],
            };
            setSelectedOtsResponses([...selectedOtsResponses, otEntityResponse]);
            //TODO aqui se hace un find contra un literal "No" esto esta mal he incluido el "No" en los pricipales idiomas Europeos
            //} else if (existingResponse && response.contentValue === 'No') {
        }
        else if (existingResponse && noValuesLanguages.includes(response.contentValue)) {
            log.debug('Had previously selected YES and then selected NO => removing it');
            selectedResponses
                .filter((r) => !procalmaMandatoryEntities.includes(r.entity))
                .forEach((foundResponse) => {
                const responseToDelete = selectedResponses.find((r) => r.entity === foundResponse.entity);
                selectedResponses.splice(selectedResponses.indexOf(responseToDelete), 1);
            });
            setSelectedOtsResponses(selectedResponses);
        }
    };
    const handleOnChangeMultiple = (responses) => {
        const selectedResponses = [...selectedOtsResponses];
        const entity = responses[0].ocs_entity;
        const existingResponse = selectedOtsResponses.find((r) => r.entity === entity);
        const wereAllResponsesFromTheCurrentInputRemoved = responses.find((r) => r.contentValue === null);
        if (existingResponse) {
            selectedResponses.splice(selectedResponses.indexOf(existingResponse), 1);
        }
        if (wereAllResponsesFromTheCurrentInputRemoved) {
            setSelectedOtsResponses(selectedResponses);
        }
        else {
            setSelectedOtsResponses([
                ...selectedResponses,
                {
                    entity: entity,
                    otResponses: responses.map((response) => {
                        return { type: response.ocs_variable, value: response.contentValue };
                    }),
                },
            ]);
        }
    };
    const handleCancel = () => {
        setSelectedOtsResponses([]); // emptying the local MultiContentOTs state
        removeOtsResponses(); // remove content from EipdContext as well
        goToNextContent(content.cancelButton.linkTo);
        scrollToTop();
    };
    const saveAndGoToNextContent = () => {
        const otsResponses = [...selectedOtsResponses.flatMap((otEntityResponse) => otEntityResponse.otResponses).values()];
        saveOtsResponses(otsResponses);
        setSelectedOtsResponses([]);
        if (content.lastStep) {
            setIsFormCompleted(true);
        }
        else {
            goToNextContent(content.nextButton.linkTo);
            scrollToTop();
        }
    };
    const isNextButtonEnabled = () => {
        const numberOfInputsInThePage = content.content.length;
        const numberOfInputsWithResponses = selectedOtsResponses.flatMap((r) => r.entity).length;
        const isProcalmaOtPage = content.id === procalmaOtPageIdentifier;
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        const hasProcalmaMandatoryEntitiesFulfilled = selectedOtsResponses.filter((r) => procalmaMandatoryEntities.includes(r.entity)).length === procalmaMandatoryEntities.length;
        if (isProcalmaOtPage &&
            isProcalmaIsPaperStoredButtonClicked &&
            !isProcalmaIsPaperStoredButtonClickedAsYes &&
            hasProcalmaMandatoryEntitiesFulfilled) {
            return true;
        }
        return numberOfInputsInThePage === numberOfInputsWithResponses;
    };
    const isInputDisabled = (multiContentItemId) => {
        const isProcalmaOtPage = content.id === procalmaOtPageIdentifier;
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        if (isProcalmaOtPage && !isProcalmaIsPaperStoredButtonClickedAsYes) {
            const procalmaSectionsThatShouldBeAlwaysEnabled = ['s6-c9-c1', 's6-c9-c2', 's6-c9-c3', 's6-c9-c4'];
            return !procalmaSectionsThatShouldBeAlwaysEnabled.includes(multiContentItemId);
        }
        return false;
    };
    const handleBinaryButtonIsSelected = (content) => {
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        const contentValue = (function () {
            switch (isProcalmaIsPaperStoredButtonClicked) {
                case true:
                    return isProcalmaIsPaperStoredButtonClickedAsYes ? 'procalma_conservacionpapel_c' : 'No';
                case false:
                    return '';
            }
        })();
        const response = {
            valueType: ContentType.BINARY,
            contentId: content.id,
            contentValue: contentValue,
        };
        return response;
    };
    log.debug('selectedOtsResponses from component state ---> ', selectedOtsResponses);
    return (_jsxs("div", Object.assign({ className: "multi-content" }, { children: [_jsxs("div", Object.assign({ className: "multi-content__body" }, { children: [_jsx("h1", Object.assign({ className: `multi-content__title ${content.description && 'no-padding-top'}`, "data-testid": "multi-content-title" }, { children: getTitle() })), content.description && _jsx("h2", Object.assign({ className: "multi-content__description ot-title" }, { children: t(content.description) })), _jsx("div", { children: content.additionalInformation && (_jsx("div", Object.assign({ className: "multi-content__additional-information" }, { children: _jsx("p", { children: t(content.additionalInformation) }) }))) }), _jsx("div", Object.assign({ className: "multi-content__questions" }, { children: content.content.map((multiContentItem) => {
                            switch (multiContentItem.type) {
                                case ContentType.BINARY:
                                    return (_jsx(MultiContentBinaryQuestion, { content: multiContentItem, handleOnClick: handleOnClick, selectedValue: handleBinaryButtonIsSelected(multiContentItem) }, multiContentItem.id));
                                case ContentType.DROPDOWN:
                                    if (isInputDisabled(multiContentItem.id)) {
                                        break;
                                    }
                                    switch (multiContentItem.subtype) {
                                        case DropdownContentSubtype.ACTIVOS:
                                            return (_jsx(DropdownActivosQuestion, { handleOnChangeMultiple: handleOnChangeMultiple, content: multiContentItem, isOptionDisable: isInputDisabled(multiContentItem.id) }, multiContentItem.id));
                                        default:
                                            return (_jsx(DropdownQuestion, { handleOnChangeMultiple: handleOnChangeMultiple, content: multiContentItem, isOptionDisable: isInputDisabled(multiContentItem.id) }, multiContentItem.id));
                                    }
                            }
                        }) }))] })), _jsxs("div", Object.assign({ className: "multi-content__next-action" }, { children: [content.cancelButton && (_jsx("button", Object.assign({ onClick: handleCancel, className: "button--cancel", "data-testid": "cancel-button" }, { children: t(content.cancelButton.title) }))), _jsx(NextButton, { onClickFn: saveAndGoToNextContent, enabled: isNextButtonEnabled(), text: content.nextButton.title })] }))] })));
};
export default MultiContentOTs;
