var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useErrorBoundary } from 'react-error-boundary';
import ContentContainer from './Content/ContentContainer';
import Menu from './Menu/Menu';
import { ContentType } from '../../../types/FormModelTypes';
import FormResponseContext from '../../../contexts/FormResponseContext';
import FormProgressContext from '../../../contexts/FormProgressContext';
import { calculateEipd } from '../../../api/signoAPI';
import { submitUserForm } from '../../../api/formService';
import UserContext from '../../../contexts/UserContext';
import EipdContext from '../../../contexts/EipdContext';
import dpdContext from '../../../contexts/DpdContext';
import { log } from '../../../utils/logger';
import GlobalContainer from '../../common/Containers/GlobalContainer';
import Header from '../../common/Header/Header';
import { scrollToTop } from '../../../utils/common-utils';
const Form = () => {
    const [currentContent, setCurrentContent] = useState(null);
    const { getFormResponses, getUserFormId, getFormResponseById } = useContext(FormResponseContext);
    const { getSectionsData, getCurrentSectionId, saveCurrentSectionId, getCompletedSectionsIds, saveCompletedSectionsIds, getFormProgressStatus, getEditableQuestionId, saveEditableQuestionId, } = useContext(FormProgressContext);
    const { getBusinessActivityId, getHasEmployees, getLogo, getCheckPersonalizationApiCalled } = useContext(UserContext);
    const { getNeedEipd } = useContext(EipdContext);
    const { getIsDpdMandatory } = useContext(dpdContext);
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        const fetchEditableContent = () => __awaiter(void 0, void 0, void 0, function* () {
            yield goToNextContent(getEditableQuestionId());
            yield scrollToTop();
        });
        if (getEditableQuestionId()) {
            fetchEditableContent();
            saveEditableQuestionId(null);
        }
    }, [getEditableQuestionId]);
    const getTargetContent = (contentId) => {
        var _a;
        const formResponses = getFormResponses();
        const flattenedSectionContents = getSectionsData().reduce((acc, current) => {
            return acc.concat(current.content);
        }, []);
        const targetContent = flattenedSectionContents.find((content) => content.id == contentId);
        targetContent.formResponse = getFormResponseById(targetContent.id);
        targetContent.formResponses = (_a = getFormResponses()) === null || _a === void 0 ? void 0 : _a.filter((fr) => fr.contentId.startsWith(`${contentId}-`));
        if (formResponses && !formResponses.some((fr) => fr.contentId === targetContent.id)) {
            if (currentContent.id === 'formStart-c1' && formResponses.length > 1) {
                targetContent.navBackLinkTo = formResponses[formResponses.length - 1].contentId;
            }
            else {
                targetContent.navBackLinkTo = currentContent.id;
            }
        }
        return targetContent;
    };
    const getSwitchContentId = (contentId) => {
        var _a, _b, _c, _d;
        const targetContent = getTargetContent(contentId);
        if (targetContent === undefined)
            return;
        if (targetContent.type === ContentType.SWITCH) {
            if (targetContent.dependsOn === 'actividad') {
                const linkTo = (_a = targetContent.cases.find((content) => content.when === getBusinessActivityId())) === null || _a === void 0 ? void 0 : _a.linkTo;
                return linkTo ? linkTo : targetContent.defaultLinkTo;
            }
            else if (targetContent.dependsOn === 'empleados') {
                return (_b = targetContent.cases.find((content) => content.when === (getHasEmployees() ? 1 : 0))) === null || _b === void 0 ? void 0 : _b.linkTo;
            }
            else if (targetContent.dependsOn === 'eipd') {
                return (_c = targetContent.cases.find((content) => content.when === (getNeedEipd() ? 1 : 0))) === null || _c === void 0 ? void 0 : _c.linkTo;
            }
            else if (targetContent.dependsOn === 'dpd') {
                return (_d = targetContent.cases.find((content) => content.when === (getIsDpdMandatory() ? 1 : 0))) === null || _d === void 0 ? void 0 : _d.linkTo;
            }
        }
    };
    const goToNextContent = (contentId) => __awaiter(void 0, void 0, void 0, function* () {
        const nextContentId = getSwitchContentId(contentId) !== undefined ? getSwitchContentId(contentId) : contentId;
        // submit form when reaching last form screen
        if (contentId === 'formEnd-c2') {
            try {
                yield submitUserForm(getFormResponses(), getUserFormId());
                saveCurrentSectionId('formStart');
            }
            catch (err) {
                log.debug('Error calling submitUserForm endpoint', err);
                showBoundary(err);
            }
        }
        if (contentId === 's6-c2') {
            calculateEipd(getUserFormId());
        }
        const nextContent = getTargetContent(nextContentId);
        setCurrentContent(nextContent);
        const section = getSectionsData().find((section) => section.content.find((content) => content.id == nextContentId));
        if (section.id != getCurrentSectionId() && section.parent != getCurrentSectionId()) {
            saveCompletedSectionsIds([...getCompletedSectionsIds(), getCurrentSectionId()]);
        }
        saveCurrentSectionId(section.id);
    });
    if (!currentContent) {
        const currentSection = getSectionsData().find((section) => section.id == getCurrentSectionId());
        setCurrentContent(currentSection.content.find((c) => c.id == currentSection.firstContent));
    }
    const navToBackContent = () => {
        const formResponses = getFormResponses();
        const formResponseByCurrentId = formResponses.find((fr) => fr.contentId === currentContent.id);
        const lastResponse = formResponses[formResponses.length - 1];
        const nextContentId = getSwitchContentId((formResponseByCurrentId === null || formResponseByCurrentId === void 0 ? void 0 : formResponseByCurrentId.navBackLinkTo) || (lastResponse === null || lastResponse === void 0 ? void 0 : lastResponse.contentId)) !== undefined
            ? getSwitchContentId((formResponseByCurrentId === null || formResponseByCurrentId === void 0 ? void 0 : formResponseByCurrentId.navBackLinkTo) || (lastResponse === null || lastResponse === void 0 ? void 0 : lastResponse.contentId))
            : (formResponseByCurrentId === null || formResponseByCurrentId === void 0 ? void 0 : formResponseByCurrentId.navBackLinkTo) || (lastResponse === null || lastResponse === void 0 ? void 0 : lastResponse.contentId);
        setCurrentContent(getTargetContent(nextContentId));
        const section = getSectionsData().find((section) => section.content.find((content) => content.id == nextContentId));
        if (section.id != getCurrentSectionId() && section.parent != getCurrentSectionId()) {
            saveCompletedSectionsIds([...getCompletedSectionsIds(), getCurrentSectionId()]);
        }
        saveCurrentSectionId(section.id);
    };
    const navToNextContent = () => {
        const formResponseByCurrentId = currentContent.formResponse;
        const nextContentId = getSwitchContentId(formResponseByCurrentId.navNextLinkTo) !== undefined
            ? getSwitchContentId(formResponseByCurrentId.navNextLinkTo)
            : formResponseByCurrentId.navNextLinkTo;
        setCurrentContent(getTargetContent(nextContentId));
        const section = getSectionsData().find((section) => section.content.find((content) => content.id == nextContentId));
        if (section.id != getCurrentSectionId() && section.parent != getCurrentSectionId()) {
            saveCompletedSectionsIds([...getCompletedSectionsIds(), getCurrentSectionId()]);
        }
        saveCurrentSectionId(section.id);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: "Signo | Protecci\u00F3n de datos" }) }), _jsxs(GlobalContainer, { children: [_jsx(Header, { title: "PROTECCI\u00D3N DE DATOS" }), _jsx("div", Object.assign({ className: "form-background" }, { children: _jsxs("div", Object.assign({ className: "global-container-form" }, { children: [currentContent && currentContent.id !== 'formStart-c1' && (_jsx(Menu, { sections: getSectionsData(), currentSectionId: getCurrentSectionId(), completedSectionsIds: getCompletedSectionsIds(), formProgressStatus: getFormProgressStatus() })), _jsx(ContentContainer, { currentContent: currentContent, goToNextContent: goToNextContent, navToBackContent: navToBackContent, navToNextContent: navToNextContent })] })) }))] })] }));
};
export default Form;
