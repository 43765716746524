var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchCollectiveId } from '../../../api/collectiveService';
import { submitUserRegistration } from '../../../api/userService';
import MenuLanguages from '../../../components/common/MenuLanguages/MenuLanguages';
import { changeColorStyle, changeFontType } from '../../../utils/formSpecificsUtils';
import { log } from '../../../utils/logger';
import { capitaliceWord } from '../../../utils/textUtils';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import PrimaryOutlineButton from '../../common/Buttons/PrimaryOutlineButton';
import ErrorPage, { ErrorEnum } from '../Error/ErrorPage';
import MainContainer from '../MainContainer';
import InitialRegistrationScreen from './OtherRegistrationScreens/InitialRegistrationScreen';
import PublicAdministrationDisclaimer from './OtherRegistrationScreens/PublicAdministrationDisclaimer';
import BusinessActivity from './RegistrationSteps/BusinessActivity';
import Intro from './RegistrationSteps/Intro';
import LegalData from './RegistrationSteps/LegalData';
import LegalDataCompany from './RegistrationSteps/LegalDataCompany';
import LegalDataSelfEmployed from './RegistrationSteps/LegalDataSelfEmployed';
import LegalRepresentative from './RegistrationSteps/LegalRepresentative';
import LocationAndContact from './RegistrationSteps/LocationAndContact';
import LoginInformation from './RegistrationSteps/LoginInformation';
import Staff from './RegistrationSteps/Staff';
import Loader from '../../../components/common/Loaders/Loader';
import ContactInfoModal from '../../../components/common/Modals/ContactInfo/ContactInfoModal';
var RegistrationSteps;
(function (RegistrationSteps) {
    RegistrationSteps[RegistrationSteps["Start"] = 0] = "Start";
    RegistrationSteps[RegistrationSteps["Intro"] = 1] = "Intro";
    RegistrationSteps[RegistrationSteps["PublicAdmin"] = 2] = "PublicAdmin";
    RegistrationSteps[RegistrationSteps["LegalData"] = 3] = "LegalData";
    RegistrationSteps[RegistrationSteps["LegalDataCompany"] = 4] = "LegalDataCompany";
    RegistrationSteps[RegistrationSteps["LegalDataSelfEmployed"] = 5] = "LegalDataSelfEmployed";
    RegistrationSteps[RegistrationSteps["LegalRepresentative"] = 6] = "LegalRepresentative";
    RegistrationSteps[RegistrationSteps["LocationAndContact"] = 7] = "LocationAndContact";
    RegistrationSteps[RegistrationSteps["BusinessActivity"] = 8] = "BusinessActivity";
    RegistrationSteps[RegistrationSteps["Staff"] = 9] = "Staff";
    RegistrationSteps[RegistrationSteps["LoginInformation"] = 10] = "LoginInformation";
})(RegistrationSteps || (RegistrationSteps = {}));
const Registration = () => {
    const { t, i18n } = useTranslation();
    const [isPublicAdmin, setIsPublicAdmin] = useState(false);
    const [isSelfEmployed, setIsSelfEmployed] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [responses, setResponses] = useState([]);
    const [registrationError, setRegistrationError] = useState(null);
    const [collectiveId, setCollectiveId] = useState(null);
    const [collectivePersonalization, setCollectivePersonalization] = useState(null);
    const [isCollectiveApiResponded, setIsCollectiveApiResponded] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const collectiveHash = searchParams.get('c');
    const { showBoundary } = useErrorBoundary();
    const [isOscIntegrationInProgress, setIsOscIntegrationInProgress] = useState(false);
    //LANGUAGE
    const [anchorLanguage, setAnchorLanguage] = React.useState(null);
    const handleOpenLanguage = (event) => {
        setAnchorLanguage(event.currentTarget);
    };
    const handleCloseLanguage = () => {
        setAnchorLanguage(null);
    };
    useEffect(() => {
        if (collectiveId === null && registrationError === null) {
            fetchCollectiveId(collectiveHash)
                .then((response) => {
                var _a, _b;
                if (response === null) {
                    setRegistrationError(ErrorEnum.REGISTRATION_INVALID_COLLECTIVE);
                }
                else {
                    setCollectiveId(response.id);
                    setCollectivePersonalization(response === null || response === void 0 ? void 0 : response.personalization);
                    if ((_a = response === null || response === void 0 ? void 0 : response.personalization) === null || _a === void 0 ? void 0 : _a.colors) {
                        changeColorStyle(response.personalization.colors);
                    }
                    if ((_b = response.personalization) === null || _b === void 0 ? void 0 : _b.fonts) {
                        changeFontType(response.personalization.fonts);
                    }
                }
                setIsCollectiveApiResponded(true);
            })
                .catch((e) => showBoundary(e));
        }
    }, [collectiveHash, collectiveId, navigate, registrationError, showBoundary]);
    const saveResponse = (response) => {
        const newResponses = [...responses];
        const existingResponse = newResponses.find((r) => r.name === response.name);
        response.value !== ''
            ? newResponses.splice(newResponses.indexOf(existingResponse), existingResponse ? 1 : 0, response)
            : newResponses.splice(newResponses.indexOf(existingResponse), 1);
        setResponses(newResponses);
    };
    const saveResponses = (newResponses) => {
        setResponses((prevResponses) => {
            const filteredResponses = newResponses.filter((newResponse) => {
                return !prevResponses.some((prevResponse) => prevResponse.name === newResponse.name);
            });
            const updatedResponses = [...prevResponses, ...filteredResponses];
            return updatedResponses;
        });
    };
    const getResponses = () => {
        return responses;
    };
    const goBack = () => {
        if (!isPublicAdmin) {
            switch (currentStep) {
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.Start);
                    break;
                case RegistrationSteps.LegalData:
                    setCurrentStep(RegistrationSteps.Intro);
                    break;
                case RegistrationSteps.LegalDataCompany:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalDataSelfEmployed:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalRepresentative:
                    setCurrentStep(RegistrationSteps.LegalDataCompany);
                    break;
                case RegistrationSteps.LocationAndContact:
                    isSelfEmployed ? setCurrentStep(RegistrationSteps.LegalDataSelfEmployed) : setCurrentStep(RegistrationSteps.LegalRepresentative);
                    break;
                case RegistrationSteps.BusinessActivity:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.Staff:
                    setCurrentStep(RegistrationSteps.BusinessActivity);
                    break;
                case RegistrationSteps.LoginInformation:
                    setCurrentStep(RegistrationSteps.Staff);
                    break;
            }
        }
        else if (isPublicAdmin && currentStep === RegistrationSteps.Intro) {
            setIsPublicAdmin(false);
            setCurrentStep(RegistrationSteps.Start);
        }
    };
    const goToNextStep = () => {
        if (isPublicAdmin) {
            switch (currentStep) {
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.PublicAdmin);
                    setIsNextButtonDisabled(true);
                    break;
                case RegistrationSteps.PublicAdmin:
                    setCurrentStep(RegistrationSteps.Intro);
                    setIsNextButtonDisabled(true);
                    break;
            }
        }
        else {
            switch (currentStep) {
                case RegistrationSteps.Start:
                    setCurrentStep(RegistrationSteps.Intro);
                    break;
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalData:
                    isSelfEmployed ? setCurrentStep(RegistrationSteps.LegalDataSelfEmployed) : setCurrentStep(RegistrationSteps.LegalDataCompany);
                    break;
                case RegistrationSteps.LegalDataCompany:
                    setCurrentStep(RegistrationSteps.LegalRepresentative);
                    break;
                case RegistrationSteps.LegalDataSelfEmployed:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.LegalRepresentative:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.LocationAndContact:
                    setCurrentStep(RegistrationSteps.BusinessActivity);
                    break;
                case RegistrationSteps.BusinessActivity:
                    setCurrentStep(RegistrationSteps.Staff);
                    break;
                case RegistrationSteps.Staff:
                    setCurrentStep(RegistrationSteps.LoginInformation);
                    break;
            }
            setIsNextButtonDisabled(true);
        }
    };
    const handleNextButton = (enable) => {
        setIsNextButtonDisabled(!enable);
    };
    const getResponseFieldValue = (field) => {
        var _a;
        return (_a = responses.find((r) => r.name == field)) === null || _a === void 0 ? void 0 : _a.value;
    };
    const signUp = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsOscIntegrationInProgress(true);
        const userRegistrationData = {
            business_name: !isSelfEmployed ? getResponseFieldValue('business_name').toString() : null,
            document_id: !isSelfEmployed ? getResponseFieldValue('document_id').toString() : null,
            address: getResponseFieldValue('address').toString(),
            zipcode: getResponseFieldValue('zipcode').toString(),
            city: getResponseFieldValue('city').toString(),
            province: getResponseFieldValue('province').toString(),
            phone_number: getResponseFieldValue('phone_number').toString(),
            email_address: getResponseFieldValue('email_address').toString(),
            activity_id: Number(getResponseFieldValue('activity_id')),
            employees: Number(getResponseFieldValue('employees').toString()),
            password: getResponseFieldValue('password').toString(),
            self_employed: Boolean(getResponseFieldValue('self_employed').toString() == 'true'),
            rlegal_name: getResponseFieldValue('rlegal_name').toString(),
            rlegal_first_surname: getResponseFieldValue('rlegal_first_surname').toString(),
            rlegal_last_surname: getResponseFieldValue('rlegal_last_surname') ? getResponseFieldValue('rlegal_last_surname').toString() : '',
            rlegal_document_id: getResponseFieldValue('rlegal_document_id').toString(),
            rlegal_role: !isSelfEmployed ? getResponseFieldValue('rlegal_role').toString() : null,
            collective_id: collectiveId,
            has_read_and_accepts_communications: getResponseFieldValue('has_read_and_accepts_communications')
                ? Boolean(getResponseFieldValue('has_read_and_accepts_communications'))
                : false,
        };
        try {
            const successfullyRegistered = yield submitUserRegistration(userRegistrationData);
            setIsOscIntegrationInProgress(false);
            if (successfullyRegistered) {
                log.debug('IN REGISTRATION COMPONENT - is successfullyRegistered ===> ', successfullyRegistered);
                navigate('/login', { state: { successMessage: t('texts.registrationPageTexts.alertMessages.successfulRegistration') } });
            }
            else {
                log.error('IN REGISTRATION COMPONENT - is NOT successfullyRegistered ===> ', successfullyRegistered);
                setRegistrationError(ErrorEnum.REGISTRATION_ERROR);
            }
        }
        catch (e) {
            setIsOscIntegrationInProgress(false);
            log.error('IN REGISTRATION COMPONENT - error ===> ', e);
            showBoundary(e);
        }
    });
    const getNif = () => {
        var _a, _b;
        return isSelfEmployed
            ? (_a = responses.find((r) => r.name == 'rlegal_document_id')) === null || _a === void 0 ? void 0 : _a.value.toString()
            : (_b = responses.find((r) => r.name == 'document_id')) === null || _b === void 0 ? void 0 : _b.value.toString();
    };
    const renderRegistrationScreenTitle = () => {
        if (currentStep === RegistrationSteps.Intro) {
            return _jsx("h1", Object.assign({ className: "registration-page__title" }, { children: t('texts.registrationPageTexts.introScreen.title') }));
        }
        else {
            return _jsx("h1", Object.assign({ className: "registration-page__title" }, { children: t('texts.registrationPageTexts.defaultTitle') }));
        }
    };
    const renderRegistrationScreenBody = () => {
        switch (currentStep) {
            case RegistrationSteps.Intro:
                return _jsx(Intro, { setIsPublicAdmin: setIsPublicAdmin, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalData:
                return _jsx(LegalData, { setIsSelfEmployed: setIsSelfEmployed, saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalDataCompany:
                return _jsx(LegalDataCompany, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalDataSelfEmployed:
                return _jsx(LegalDataSelfEmployed, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LocationAndContact:
                return (_jsx(LocationAndContact, { saveResponse: saveResponse, saveResponses: saveResponses, getResponses: getResponses, handleNextButton: handleNextButton }));
            case RegistrationSteps.BusinessActivity:
                return _jsx(BusinessActivity, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.Staff:
                return _jsx(Staff, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalRepresentative:
                return _jsx(LegalRepresentative, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LoginInformation:
                return (_jsx(LoginInformation, { saveResponse: saveResponse, handleNextButton: handleNextButton, nif: getNif(), collectivePersonalization: collectivePersonalization }));
        }
    };
    const renderRegistrationScreenButtons = () => {
        return (_jsxs("div", Object.assign({ className: "registration-page__next-buttons" }, { children: [_jsx(PrimaryOutlineButton, { text: "Atr\u00E1s", onClickFn: goBack, testId: "back-button", enabled: true, submitButton: false }), _jsx(PrimaryButton, { text: isOscIntegrationInProgress
                        ? t('texts.registrationPageTexts.buttons.processing')
                        : currentStep == RegistrationSteps.LoginInformation
                            ? t('texts.registrationPageTexts.buttons.submit')
                            : t('texts.registrationPageTexts.buttons.goNext'), onClickFn: currentStep == RegistrationSteps.LoginInformation ? signUp : goToNextStep, testId: "next-button", isDisabled: isNextButtonDisabled || isOscIntegrationInProgress })] })));
    };
    return !isCollectiveApiResponded ? (_jsx(Loader, {}) // Aquí renderizas la pantalla de login
    ) : registrationError !== null ? (_jsx(ErrorPage, { error: registrationError })) : currentStep === RegistrationSteps.Start ? (_jsxs(_Fragment, { children: [_jsx(ContactInfoModal, {}), _jsx(InitialRegistrationScreen, { collectivePersonalization: collectivePersonalization, goToNextStep: goToNextStep, isCollectiveApiResponded: isCollectiveApiResponded })] })) : currentStep !== RegistrationSteps.PublicAdmin ? (_jsxs(_Fragment, { children: [_jsx(ContactInfoModal, {}), _jsx(MainContainer, Object.assign({ collectivePersonalization: collectivePersonalization, isCollectiveApiResponded: isCollectiveApiResponded }, { children: _jsxs("div", Object.assign({ className: "main-content registration-page" }, { children: [_jsxs("div", Object.assign({ className: "language-registration" }, { children: [_jsxs(IconButton, Object.assign({ onClick: handleOpenLanguage, "aria-label": t('common.ariaLabels.languages') }, { children: [_jsx(LanguageOutlinedIcon, {}), _jsx("span", Object.assign({ className: "language-text" }, { children: capitaliceWord(i18n.language) }))] })), _jsx(MenuLanguages, { anchorEl: anchorLanguage, onClose: handleCloseLanguage })] })), renderRegistrationScreenTitle(), renderRegistrationScreenBody(), renderRegistrationScreenButtons()] })) }))] })) : (_jsxs(_Fragment, { children: [_jsx(ContactInfoModal, {}), _jsx(PublicAdministrationDisclaimer, { collectivePersonalization: collectivePersonalization, goBack: goToNextStep })] }));
};
export default Registration;
