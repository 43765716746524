import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActivities } from '../../../../api/signoAPI';
import { log } from '../../../../utils/logger';
import Dropdown from '../../../common/Selectors/Dropdown';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
const BusinessActivity = ({ saveResponse, handleNextButton }) => {
    const { t } = useTranslation();
    const [responses, setResponses] = useState([]);
    const [activities, setActivities] = useState([]);
    useEffect(() => {
        if (activities.length === 0) {
            getActivities()
                .then(({ data }) => {
                const businessActivitiesValues = [...data].map((activity, index) => {
                    return {
                        order: index,
                        text: activity.name,
                        value: activity.id.toString(),
                    };
                });
                setActivities(businessActivitiesValues);
            })
                .catch((err) => log.debug(err));
        }
        if ((responses === null || responses === void 0 ? void 0 : responses.length) == 1) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, activities.length]);
    const businessActivityCode = {
        label: 'De entre los siguientes tipos de actividad, ¿cuál dirías que la describe mejor?',
        placeholder: 'Selecciona del desplegable o escribe',
        values: activities,
    };
    const onChange = (value, name) => {
        const response = {
            name: name,
            value: value,
        };
        saveResponse(response);
        updateResponse(response);
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    return (_jsxs("div", Object.assign({ className: "registration-page__content" }, { children: [_jsx("h2", Object.assign({ className: "registration-page__content-subtitle", "data-testid": 'business-activity-page-title' }, { children: t('texts.registrationPageTexts.businessActivityScreen.subtitle').toUpperCase() })), _jsx("div", Object.assign({ className: "registration-page__content-body" }, { children: _jsx("div", Object.assign({ className: "registration-page__content-question" }, { children: _jsx(Dropdown, { label: businessActivityCode.label, placeholder: businessActivityCode.placeholder, questionValues: businessActivityCode.values, onChangeFn: onChange, fieldName: 'activity_id', isMultiSelect: false }) })) }))] })));
};
export default BusinessActivity;
