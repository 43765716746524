import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { getCompanyIdFromToken } from '../api/authHelper';
const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
    const [token, setToken_] = useState(localStorage.getItem('token'));
    const logout = () => {
        setToken(null);
        window.location.href = '/';
    };
    const setToken = (newToken) => {
        setToken_(newToken);
    };
    useEffect(() => {
        if (token) {
            localStorage.removeItem('client-token');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            localStorage.setItem('token', token);
        }
        else {
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
        }
    }, [token]);
    const contextValue = useMemo(() => ({
        token,
        setToken,
        logout,
        getCompanyId: () => {
            return getCompanyIdFromToken(token);
        },
    }), [token]);
    // Provide the authentication context to the children components
    return _jsx(AuthContext.Provider, Object.assign({ value: contextValue }, { children: children }));
};
export const useAuth = () => {
    return useContext(AuthContext);
};
export default AuthProvider;
