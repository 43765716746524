import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import NextButton from '../../../../common/Buttons/NextButton';
import EipdContext from '../../../../../contexts/EipdContext';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../utils/common-utils';
const OtIntroScreen = ({ content, goToNextContent }) => {
    const { t } = useTranslation();
    const { getCurrentOt, saveCurrentOt } = useContext(EipdContext);
    const getTitle = () => {
        return getCurrentOt() !== undefined ? getCurrentOt().title : t(content.title);
    };
    const getDescription = () => {
        return getCurrentOt() !== undefined ? getCurrentOt().purpose : t(content.description);
    };
    const handleCancel = () => {
        saveCurrentOt(null);
        goToNextContent(content.cancelButton.linkTo);
        scrollToTop();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "ot-intro" }, { children: _jsxs("div", Object.assign({ className: "ot-intro__header" }, { children: [_jsx("h1", Object.assign({ className: "ot-intro__header-title", "data-testid": "content-title" }, { children: getTitle() })), _jsx("p", Object.assign({ className: "ot-intro__header-description", "data-testid": "content-description" }, { children: getDescription() }))] })) })), _jsxs("div", Object.assign({ className: "multi-content__next-action" }, { children: [content.cancelButton && (_jsx("button", Object.assign({ onClick: handleCancel, className: "button--cancel", "data-testid": "cancel-button" }, { children: t(content.cancelButton.title) }))), _jsx(NextButton, { onClickFn: () => { goToNextContent(content.nextButton.linkTo); scrollToTop(); }, enabled: true, text: t(content.nextButton.title) })] }))] }));
};
export default OtIntroScreen;
