import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
const RegistrationBinaryQuestion = ({ content, onClick }) => {
    const [selectedResponse, setSelectedResponse] = useState('');
    const isSelected = (question) => {
        return question && question.value === selectedResponse;
    };
    const handleOnClick = (question) => {
        setSelectedResponse(question.value);
        onClick(question.value);
    };
    return (_jsxs("div", Object.assign({ className: "question__answers-binary", "data-testid": "binary-type-question" }, { children: [_jsx("label", Object.assign({ className: "question__answers-binary-label registration-page__content-binary-question-label", "data-testid": "question-label" }, { children: content.description })), _jsx("div", Object.assign({ className: "question__answers-binary-buttons" }, { children: content.questionValues.map((question, index) => {
                    return (_jsx("button", Object.assign({ "data-testid": `binary-button-${question.text}`, onClick: () => handleOnClick(question), className: `button--multi-content-binary registration-page__binary-question-button ${isSelected(question) ? 'button--multi-content-binary--selected' : ''}` }, { children: question.text.toUpperCase() }), index));
                }) }))] })));
};
export default RegistrationBinaryQuestion;
