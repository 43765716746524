import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorBoundary } from 'react-error-boundary';
import { FormProgressContextProvider } from '../../../../contexts/FormProgressContext';
import { FormResponseContextProvider } from '../../../../contexts/FormResponseContext';
import { EipdContextProvider } from '../../../../contexts/EipdContext';
// import ContactInfoModalWithResetLink from '../../../../components/common/Modals/ContactInfo/ContactInfoModalWithResetLink';
import { DpdContextProvider } from '../../../../contexts/DpdContext';
import ErrorPage, { ErrorEnum } from '../../../pages/Error/ErrorPage';
import dataJson from '../../../../data/formModel.json';
const AuthenticatedOnly = ({ children }) => {
    const data = dataJson;
    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorPage, { error: ErrorEnum.GENERIC }) }, { children: _jsx(FormResponseContextProvider, { children: _jsx(FormProgressContextProvider, Object.assign({ sectionsData: data.sections }, { children: _jsx(EipdContextProvider, { children: _jsx(DpdContextProvider, { children: children }) }) })) }) })));
};
export default AuthenticatedOnly;
