import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getLanguagesSrv from '../../api/languages/languagesSrv';
import useError from '../useErrors';
const useLanguages = () => {
    const { addError } = useError();
    const { t } = useTranslation();
    const [languages, setLanguages] = useState(null);
    const getLanguages = () => {
        getLanguagesSrv()
            .then((res) => {
            const sortedLanguages = [...res].sort((a, b) => t(`languages.${a.tag}`).localeCompare(t(`languages.${b.tag}`)));
            setLanguages(sortedLanguages);
        })
            .catch(addError);
    };
    useEffect(() => getLanguages(), []);
    return {
        languages,
    };
};
export default useLanguages;
