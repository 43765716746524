import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AuthProvider from './contexts/AuthContext';
import { UserContextProvider } from './contexts/UserContext';
import Routes from './routes/Routes';
import MaintenanceContextProvider from './contexts/MaintenanceContext';
import { ThemeProvider } from '@mui/material';
import themeDefault, { createDynamicTheme } from './themes/default';
import { DateLocaleProvider } from './contexts/DateLocaleContext';
import SnackbarFeedback from './components/common/SnackbarFeedback/SnackbarFeedback';
function App() {
    //const theme = themeDefault;
    const [theme, setTheme] = useState(themeDefault);
    useEffect(() => {
        const handleCssChange = () => {
            setTheme(createDynamicTheme());
        };
        // Observa cambios en las variables CSS
        const observer = new MutationObserver(handleCssChange);
        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['style'], // Escucha cambios en los estilos (CSS variables)
        });
        return () => observer.disconnect();
    }, []);
    return (_jsx(DateLocaleProvider, { children: _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsxs("div", Object.assign({ className: "App" }, { children: [_jsx(Helmet, { children: _jsx("title", { children: "Signo | Cumplimiento normativo" }) }), _jsx(MaintenanceContextProvider, { children: _jsx(AuthProvider, { children: _jsx(UserContextProvider, { children: _jsx(Routes, {}) }) }) }), _jsx(SnackbarFeedback, {})] })) })) }));
}
export default App;
