var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import { log } from '../utils/logger';
import { publishOt } from '../api/otService';
import FormResponseContext from './FormResponseContext';
const EipdContext = createContext(null);
export const EipdContextProvider = ({ children, ot = null }) => {
    const [needEipd, setNeedEipd] = useState(null);
    const [currentOt, setCurrentOt] = useState(ot);
    const [generatedActivos, setGeneratedActivos] = useState(null);
    const [otsResponses, setOtsResponses] = useState([]);
    const { getUserFormId } = useContext(FormResponseContext);
    const getNeedEipd = () => {
        return needEipd;
    };
    const saveNeedEipd = (eipdResult) => {
        setNeedEipd(eipdResult);
    };
    const getCurrentOt = () => {
        return currentOt;
    };
    const saveCurrentOt = (currentOt) => {
        setCurrentOt(currentOt);
    };
    const getGeneratedActivos = () => {
        return generatedActivos;
    };
    const saveGeneratedActivos = (activos) => {
        setGeneratedActivos(activos);
    };
    const getOtsResponses = () => {
        return otsResponses;
    };
    const saveOtsResponses = (responses) => {
        log.debug('saving Responses in EIPD Context', responses);
        setOtsResponses([...otsResponses, ...responses]);
    };
    const removeOtsResponses = () => {
        setOtsResponses([]);
    };
    const publishOtsResponses = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const wasSuccessful = yield publishOt(getUserFormId(), (_a = getCurrentOt()) === null || _a === void 0 ? void 0 : _a.id, otsResponses);
            if (!wasSuccessful) {
                log.error('publishOt failed');
                setOtsResponses([]);
                return false;
            }
            else {
                log.debug('publishOt was Successful');
                setOtsResponses([]);
                return true;
            }
        }
        catch (e) {
            log.error('publishOt failed');
            setOtsResponses([]);
            return false;
        }
    });
    const value = {
        getNeedEipd,
        saveNeedEipd,
        getCurrentOt,
        saveCurrentOt,
        getGeneratedActivos,
        saveGeneratedActivos,
        getOtsResponses,
        saveOtsResponses,
        removeOtsResponses,
        publishOtsResponses,
    };
    return _jsx(EipdContext.Provider, Object.assign({ value: value }, { children: children }));
};
export default EipdContext;
