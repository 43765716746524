var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEipdResult } from '../../../../../api/signoAPI';
import { isDocumentationGenerated } from '../../../../../api/documentationService';
import FormProgressContext, { FormStatus } from '../../../../../contexts/FormProgressContext';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import EipdContext from '../../../../../contexts/EipdContext';
import DpdContext from '../../../../../contexts/DpdContext';
import { ButtonIcon, ContentType } from '../../../../../types/FormModelTypes';
import PrimaryButton from '../../../../common/Buttons/PrimaryButton';
import dataJson from '../../../../../data/formModel.json';
import { getNewLinkToForQuestionS3S3C1 } from '../../../../../utils/formSpecificsUtils';
import { log } from '../../../../../utils/logger';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../utils/common-utils';
const IntroAndEndScreen = ({ content, goToNextContent }) => {
    const { t } = useTranslation();
    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const { getUserFormId, getFormResponses, removeFormResponseById } = useContext(FormResponseContext);
    const { getFormProgressStatus } = useContext(FormProgressContext);
    const { saveNeedEipd } = useContext(EipdContext);
    const { getIsDpdMandatory } = useContext(DpdContext);
    const navigate = useNavigate();
    const questionsData = dataJson;
    const description = { __html: t(content.description) };
    const formIsInProgress = getFormProgressStatus() === FormStatus.IN_PROGRESS;
    useEffect(() => {
        log.debug('FORM STATUS ===> ', getFormProgressStatus());
        if (isDownloadButtonDisabled && content.id === 'formEnd-c2') {
            const intervalCall = setInterval(() => {
                isDocumentationGenerated(getUserFormId())
                    .then((documentationHasBeenGenerated) => {
                    log.debug('Documentation has been generated', documentationHasBeenGenerated);
                    if (documentationHasBeenGenerated)
                        setIsDownloadButtonDisabled(false);
                })
                    .catch((err) => log.error('Error calling getIsDocumentationGenerated endpoint', err));
            }, 10000);
            return () => {
                clearInterval(intervalCall);
            };
        }
        if (isDownloadButtonDisabled && content.id === 'formStart-c1') {
            setIsDownloadButtonDisabled(false);
        }
        if (content.id === 'formStart-c1' && getUserFormId() !== undefined && getFormProgressStatus() !== FormStatus.COMPLETED) {
            setIsNextButtonDisabled(false);
        }
        else {
            setIsNextButtonDisabled(true);
        }
    }, [getFormProgressStatus, getUserFormId, isDownloadButtonDisabled, content.id]);
    const getDownloadButtonTitle = () => {
        return isDownloadButtonDisabled && content.id === 'formEnd-c2' ? content.downloadButton.titleWhenDisabled : content.downloadButton.title;
    };
    const getNextButtonTitle = () => {
        return content.id === 'formStart-c1' && formIsInProgress ? content.nextButton.alternativeTitle : content.nextButton.title;
    };
    const handleDownloadButtonClick = () => {
        navigate('/documentation');
    };
    const gotoNextContentWhenFormIsInProgress = () => __awaiter(void 0, void 0, void 0, function* () {
        const handleContentTypeBinary = (lastCompletedQuestion, lastCompletedResponse, previousResponses) => {
            let nextContentId = lastCompletedQuestion.questionValues[parseInt(lastCompletedResponse.contentValue)].linkTo; // TODO: refactor
            if (getIsDpdMandatory() !== null && nextContentId === 's3.3-c2') {
                nextContentId = getNewLinkToForQuestionS3S3C1('s3.3-c2', previousResponses, getIsDpdMandatory()); // TODO: refactor this specific case
            }
            return nextContentId;
        };
        const handleContentIterativeResume = (lastCompletedResponse, lastCompletedQuestion) => {
            if (lastCompletedResponse.contentValue === '0') {
                return lastCompletedQuestion.questionValues[0].linkTo; // TODO: refactor
            }
            else if (lastCompletedResponse.contentValue === '1' && !lastCompletedResponse.iterativeContent.length) {
                removeFormResponseById(lastCompletedResponse.contentId, getUserFormId());
                return lastCompletedResponse.contentId;
            }
            else if (lastCompletedResponse.contentValue === '1' && lastCompletedResponse.iterativeContent.length > 0) {
                return lastCompletedResponse.contentId;
            }
        };
        // Get the last completed response and redirect to the next content
        const previousResponses = getFormResponses();
        const lastCompletedResponse = previousResponses[previousResponses.length - 1];
        const allContents = questionsData.sections.flatMap((section) => section.content.map((content) => content));
        const lastCompletedQuestion = allContents.find((content) => content.id === lastCompletedResponse.contentId.replace(/^([^-]+-[^-]+).*$/, '$1'));
        const endOfThirdPartiesSectionIds = ['s5-c2', 's5-c7', 's5-c8', 's5-c3'];
        const isLastStepJustBeforeEipCalculation = endOfThirdPartiesSectionIds.includes(lastCompletedQuestion.id);
        const iterativeOtsScreensIds = ['s6-c8', 's6-c9', 's6-c10', 's6-c11'];
        const isLastStepEipdSummary = iterativeOtsScreensIds.includes(lastCompletedQuestion.id);
        const eipdSummaryPageId = 's6-c6';
        const needMoreInformationPageId = 's6-c5';
        const doNotNeedMoreInformationPageId = 's6-c4';
        let nextContentId;
        if (isLastStepJustBeforeEipCalculation || isLastStepEipdSummary) {
            const needsEipd = yield getEipdResult(getUserFormId());
            if (needsEipd !== null) {
                if (needsEipd) {
                    nextContentId = isLastStepEipdSummary ? eipdSummaryPageId : needMoreInformationPageId;
                }
                else {
                    nextContentId = doNotNeedMoreInformationPageId;
                }
                saveNeedEipd(needsEipd);
                goToNextContent(nextContentId);
                scrollToTop();
                return;
            }
        }
        if (lastCompletedQuestion.type === ContentType.BINARY) {
            nextContentId = handleContentTypeBinary(lastCompletedQuestion, lastCompletedResponse, previousResponses);
        }
        else if (lastCompletedQuestion.type === ContentType.ITERATIVE_RESUME) {
            nextContentId = handleContentIterativeResume(lastCompletedResponse, lastCompletedQuestion);
        }
        else if (lastCompletedQuestion.type === ContentType.MULTI_CONTENT && lastCompletedQuestion.iterativeContentParentId) {
            nextContentId = lastCompletedQuestion.iterativeContentParentId;
        }
        else if (lastCompletedQuestion.type === ContentType.TEXT ||
            lastCompletedQuestion.type === ContentType.MULTIPLE ||
            lastCompletedQuestion.type === ContentType.MULTI_CONTENT ||
            lastCompletedQuestion.type === ContentType.INFO) {
            nextContentId = lastCompletedQuestion.nextButton.linkTo;
        }
        goToNextContent(nextContentId);
        scrollToTop();
    });
    const handleNextButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (formIsInProgress) {
            yield gotoNextContentWhenFormIsInProgress();
        }
        else {
            goToNextContent(content.nextButton.linkTo);
            scrollToTop();
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "card__body" }, { children: _jsxs("div", Object.assign({ className: "card__header" }, { children: [_jsx("h1", Object.assign({ className: "card__header-title", "data-testid": "content-title" }, { children: t(content.title) })), _jsx("p", { className: "card__header-description", "data-testid": "content-description", dangerouslySetInnerHTML: (description) })] })) })), _jsxs("div", Object.assign({ className: "card__buttons" }, { children: [content.downloadButton && (_jsx("div", Object.assign({ className: "card__button" }, { children: _jsx(PrimaryButton, { text: getDownloadButtonTitle(), onClickFn: handleDownloadButtonClick, testId: "primary-button-download", icon: isDownloadButtonDisabled && content.id === 'formEnd-c2' ? ButtonIcon.SPINNER : null, size: 'M', isDisabled: isDownloadButtonDisabled || !isNextButtonDisabled }) }))), content.nextButton && (_jsx("div", Object.assign({ className: "card__button" }, { children: _jsx(PrimaryButton, { text: getNextButtonTitle(), onClickFn: handleNextButtonClick, testId: "primary-button-next", size: 'M', isDisabled: isNextButtonDisabled }) })))] }))] }));
};
export default IntroAndEndScreen;
