export const BASE_URL = 'https://stsignostagewesteu001.blob.core.windows.net/signo-web-images-storage/';
const createPaths = (paths) => Object.keys(paths).reduce((acc, key) => {
    acc[key] = BASE_URL + paths[key];
    return acc;
}, {});
export const taskPriority = createPaths({
    '1': 'PriorityCritica.svg',
    '2': 'PriorityAlta.svg',
    '3': 'PriorityMedia.svg',
    '4': 'PriorityBaja.svg',
});
export const taskType = createPaths({
    '1': 'tareaFirma.svg',
    '2': 'tareaRevision.svg',
    '3': 'tareaComunicaciones.svg',
    '4': 'tareaImplementacion.svg',
    '5': 'tareaFormaciones.svg',
    '6': 'tareaRegistro.svg',
    '7': 'tareaSeguimiento.svg',
});
export const getUrlBase = () => BASE_URL;
const images = {
    LOGO_URL: BASE_URL + 'Logo_Signo.svg',
    INFO_ICON_WHITE_URL: BASE_URL + 'Icono_26x26_Informacion_blanco_Signo.png',
    INFO_ICON_BLUE_URL: BASE_URL + 'Icono_102 × 102_Informacion_azul_Signo.png',
    logo: BASE_URL + 'logo.svg',
    menuIcon: BASE_URL + 'menuIcon.svg',
    menuDialog: BASE_URL + 'menuDialog.svg',
    notificationIcon: BASE_URL + 'notification.svg',
    flecha: BASE_URL + 'Flecha.svg',
    doc: BASE_URL + 'doc.svg',
    notes: BASE_URL + 'notes.svg',
    carpeta: BASE_URL + 'carpeta.svg',
    panel: BASE_URL + 'Panel.svg',
    closeSession: BASE_URL + 'CloseSession.svg',
    form: BASE_URL + 'Form.svg',
    close: BASE_URL + 'close.svg',
    nodata: BASE_URL + 'nodata_1.png',
    headerSummaryFormPdf: BASE_URL + 'cabecera_summary_form_pdf.jpg',
    arrowTop: BASE_URL + 'arrowTop.svg',
    arrowBottom: BASE_URL + 'arrowBottom.svg',
    arrowRight: BASE_URL + 'arrowRight.svg',
    editResponse: BASE_URL + 'edit-response.svg',
    btnArrowRightWhite: BASE_URL + 'btn_arrow_right_white.svg',
    btnArrowRightBlack: BASE_URL + 'btn_arrow_right_black.svg',
    info: BASE_URL + 'info.svg',
    arrowV2Left: BASE_URL + 'arrow_v2_left.svg',
    arrowV2Right: BASE_URL + 'arrow_v2_right.svg',
    video: BASE_URL + 'video.svg',
    infoBlack: BASE_URL + 'Info_negativo.svg',
    board: BASE_URL + 'board_1.svg',
    list: BASE_URL + 'list_1.svg',
    boardNegative: BASE_URL + 'board_negative_1.svg',
    listNegative: BASE_URL + 'list_negative_1.svg',
    download: BASE_URL + 'download.svg',
    PDF: BASE_URL + 'pdf.svg',
    logoSignoSvg: BASE_URL + 'logo_signo.svg',
    filter: BASE_URL + 'filters.svg',
    filterHover: BASE_URL + 'filtersHover.svg',
    delete: BASE_URL + 'delete.svg',
    tasks: {
        calendar: BASE_URL + 'Calendario.svg',
        priority: taskPriority,
        type: taskType,
        complete: BASE_URL + 'taskComplete.svg',
        pending: BASE_URL + 'pending.svg',
        inProgress: BASE_URL + 'process.svg',
        priority1d: BASE_URL + 'PriorityCriticaDisabled.svg',
        priority2d: BASE_URL + 'PriorityAltaDisabled.svg',
        priority3d: BASE_URL + 'PriorityMedia.svg',
        priority4d: BASE_URL + 'PriorityBajaDisabled.svg',
    },
};
export default images;
