import { useState } from 'react';
// TODO: ver en el futuro como se gestiona (contexto + component) global¿¿??
const useErrors = () => {
    const [errors, setErrors] = useState([]);
    const addError = (err) => {
        errors.push(err);
        setErrors([...errors]);
    };
    return {
        errors,
        addError
    };
};
export default useErrors;
