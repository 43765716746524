// Method to check if binary question 's5-c8' needs to be displayed or not before end of section, depending on the answer to question 's5-c7-c1' (terceros con acceso a datos)
export const checkWetherToDisplayQuestionS5C8 = (getFormResponseById, content, questionValue) => {
    const responsesToQuestionThirdPartiesHaveAccessToData = getFormResponseById(content.id)
        .iterativeContent.flatMap((iterativeResponsesArray) => iterativeResponsesArray.content)
        .filter((r) => r.contentId === 's5-c7-c1');
    if (responsesToQuestionThirdPartiesHaveAccessToData.find((r) => r.contentValue === '1')) {
        questionValue.linkTo = 's5-c8';
    }
};
//TODO: Refactorize this method, this method detects the last question of MultiContent/Iterativecontent
// Method to check if user is on last screen of iterative content or not
export const isLastPageOfIterativeContent = (content, selectedResponses) => {
    var _a, _b;
    return ((content.page === 1 && ((_a = selectedResponses.find((r) => r.ocs_variable === 'comercioelectronico_c')) === null || _a === void 0 ? void 0 : _a.contentValue) === '0') || // websites section
        (content.page === 2 && ((_b = selectedResponses.find((r) => r.ocs_variable === 'comercioelectronico_c')) === null || _b === void 0 ? void 0 : _b.contentValue) === '1') || // websites section
        (content.page === 3 && content.id === 's5-c7') // third-parties section
    );
};
// Questions ids that hold the value that will be used as title of iterative content screens
export const contentIdsForCustomTitle = ['s2-c4-c1', 's5-c4-c2']; // 'nombrepagina_c' (websites section), 'CONVERSIA_RazonSocial_c' (third-parties section)
// Method to check to which screen to redirect binary question 's3.3-c1' when answering yes, depending on user's previous answers to binary questions 's1.2-c3' and 's1.1-c11' (DPD and international transfers)
export const getNewLinkToForQuestionS3S3C1 = (defaultLinkToInJsonModel, previousResponses, isDpdMandatory) => {
    const dpdQuestionId = 's1.2-c3';
    const internationalTransfersQuestionId = 's1.1-c11';
    const answerToQuestion = (questionId) => {
        var _a;
        return (_a = previousResponses.find((r) => r.contentId === questionId)) === null || _a === void 0 ? void 0 : _a.contentValue;
    };
    let linkTo;
    if (answerToQuestion(dpdQuestionId) === '1' || isDpdMandatory) {
        if (answerToQuestion(internationalTransfersQuestionId) === '0') {
            linkTo = 's3.3-c3'; // CASE 2 - Has voluntary DPD or mandatory DPD, and does not do international transfers
        }
        else {
            linkTo = defaultLinkToInJsonModel; // CASE 1 - Has DPD (mandatory or voluntary) and does international transfers
        }
    }
    else {
        if (answerToQuestion(internationalTransfersQuestionId) === '0') {
            linkTo = 's3.3-c5'; // CASE 4 - Has no DPD and does not do international transfers
        }
        else {
            linkTo = 's3.3-c4'; // CASE3 - Has no DPD and does international transfers
        }
    }
    return linkTo;
};
// TODO: ver si hay una mejor forma de aplicar los cambios al root de las css. Invertigar
export const changeColorStyle = (collectiveColors) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    // TODO: crear archivos de constantes y aplicar 
    document.documentElement.style.setProperty('--color-primary', (_a = collectiveColors.colorPrimary) !== null && _a !== void 0 ? _a : '#0A1E2D');
    document.documentElement.style.setProperty('--color-primary-dark', (_b = collectiveColors.colorPrimaryDark) !== null && _b !== void 0 ? _b : '#2e0427');
    document.documentElement.style.setProperty('--color-primary-hover', (_c = collectiveColors.colorPrimaryHover) !== null && _c !== void 0 ? _c : '#2e0427');
    document.documentElement.style.setProperty('--color-primary-darker', (_d = collectiveColors.colorPrimaryDarker) !== null && _d !== void 0 ? _d : '#1f2037');
    document.documentElement.style.setProperty('--color-primary-light', (_e = collectiveColors.colorPrimaryLight) !== null && _e !== void 0 ? _e : '#156390');
    document.documentElement.style.setProperty('--color-primary-lighter', (_f = collectiveColors.colorPrimaryLighter) !== null && _f !== void 0 ? _f : '#2E628C');
    document.documentElement.style.setProperty('--color-secondary', (_g = collectiveColors.colorSecondary) !== null && _g !== void 0 ? _g : '#c41a3b');
    document.documentElement.style.setProperty('--color-secondary-hover', (_h = collectiveColors.colorSecondaryHover) !== null && _h !== void 0 ? _h : '#c41a3b');
    document.documentElement.style.setProperty('color-grey-900', (_j = collectiveColors.colorGrey900) !== null && _j !== void 0 ? _j : '#1f2037');
    document.documentElement.style.setProperty('color-grey-600', (_k = collectiveColors.colorGrey600) !== null && _k !== void 0 ? _k : '#6C757D');
    document.documentElement.style.setProperty('color-grey-500', (_l = collectiveColors.colorGrey500) !== null && _l !== void 0 ? _l : '#ADB5BD');
    document.documentElement.style.setProperty('color-grey-300', (_m = collectiveColors.colorGrey300) !== null && _m !== void 0 ? _m : '#babee0');
    document.documentElement.style.setProperty('color-grey-100', (_o = collectiveColors.colorGrey100) !== null && _o !== void 0 ? _o : '#F8F9FA');
    document.documentElement.style.setProperty('--color-grey-lighter', (_p = collectiveColors.colorGreyLighter) !== null && _p !== void 0 ? _p : '#F0F0F0');
    document.documentElement.style.setProperty('--color-white', (_q = collectiveColors.colorWhite) !== null && _q !== void 0 ? _q : '#FFF');
    document.documentElement.style.setProperty('--color-black', (_r = collectiveColors.colorBlack) !== null && _r !== void 0 ? _r : '#000');
    document.documentElement.style.setProperty('--color-success', (_s = collectiveColors.colorSuccess) !== null && _s !== void 0 ? _s : '#babee0');
};
export const changeFontType = (collectiveFonts) => {
    var _a, _b;
    // TODO: crear archivos de constantes y aplicar
    if (collectiveFonts.googleFontsUrls && Array.isArray(collectiveFonts.googleFontsUrls)) {
        collectiveFonts.googleFontsUrls.forEach((fontUrl) => {
            const link = document.createElement("link");
            link.href = fontUrl;
            link.rel = "stylesheet";
            document.head.appendChild(link);
        });
    }
    document.documentElement.style.setProperty('--main-font', (_a = collectiveFonts.mainFont) !== null && _a !== void 0 ? _a : 'Mada');
    document.documentElement.style.setProperty('--secondary-font', (_b = collectiveFonts.secondaryFont) !== null && _b !== void 0 ? _b : 'Poppins');
};
