import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import SectionItem from './SectionItem';
import UserContext from '../../../../contexts/UserContext';
import { FormStatus } from '../../../../contexts/FormProgressContext';
import { useTranslation } from 'react-i18next';
const Menu = ({ sections, currentSectionId, completedSectionsIds, formProgressStatus }) => {
    const { t } = useTranslation();
    const [menuSections, setMenuSections] = useState([]);
    const { getHasEmployees } = useContext(UserContext);
    useEffect(() => {
        const sectionsToExcludeFromMenu = ['formEnd', 'formStart'];
        const getParentSections = () => sections.filter((section) => !section.parent && !sectionsToExcludeFromMenu.includes(section.id));
        const getParentSectionsIds = () => getParentSections().flatMap((section) => section.id);
        const getSubsectionsOfASection = (sectionId) => {
            return sections.filter((section) => section.parent === sectionId && sectionsToExcludeFromMenu.map((s) => s !== section.parent));
        };
        const getSubsectionsIds = (parentSectionId) => getSubsectionsOfASection(parentSectionId).flatMap((subsection) => subsection.id);
        const isLastSection = (sectionOrder) => {
            return sectionOrder === getParentSections().length;
        };
        const isFirstSection = (sectionOrder) => {
            return sectionOrder === 1;
        };
        const getPreviousSectionId = (section) => {
            return section.order > 1 ? sections.find((prevSection) => prevSection.order == section.order - 1 && !prevSection.parent).id : null;
        };
        const getAllSectionsIdsThatDependOn = (companyAttribute) => {
            return sections.filter((section) => section.dependsOn === companyAttribute).map((section) => section.id);
        };
        const getSubsectionsToDisplay = (sectionId) => {
            if (!getHasEmployees()) {
                const subsectionsToHide = [];
                getSubsectionsIds(sectionId).forEach((subsectionId) => {
                    if (getAllSectionsIdsThatDependOn('empleados').includes(subsectionId)) {
                        subsectionsToHide.push(subsectionId);
                    }
                });
                return getSubsectionsOfASection(sectionId).filter((section) => !subsectionsToHide.includes(section.id));
            }
            else {
                return getSubsectionsOfASection(sectionId);
            }
        };
        const getSectionsToDisplay = () => {
            if (!getHasEmployees()) {
                const sectionsToHide = [];
                getParentSectionsIds().forEach((sectionId) => {
                    if (getAllSectionsIdsThatDependOn('empleados').includes(sectionId)) {
                        sectionsToHide.push(sectionId);
                    }
                });
                return getParentSections().filter((section) => !sectionsToHide.includes(section.id));
            }
            else {
                return getParentSections();
            }
        };
        const getSubsections = (id) => {
            return getSubsectionsToDisplay(id)
                .sort((ss1, ss2) => (ss1.order > ss2.order ? 1 : -1))
                .map((section) => {
                return {
                    id: section.id,
                    sectionTitle: section.sectionTitle,
                    isLastSection: isLastSection(section.order),
                    isFirstSection: isFirstSection(section.order),
                    isCompleted: completedSectionsIds.includes(section.id),
                };
            });
        };
        setMenuSections(getSectionsToDisplay()
            .sort((s1, s2) => (s1.order > s2.order ? 1 : -1))
            .map((section) => {
            return {
                id: section.id,
                previousSectionId: getPreviousSectionId(section),
                sectionTitle: section.sectionTitle,
                subsections: getSubsections(section.id),
                isLastSection: isLastSection(section.order),
                isFirstSection: isFirstSection(section.order),
                isCompleted: formProgressStatus === FormStatus.COMPLETED || completedSectionsIds.includes(section.id),
            };
        }));
    }, [sections, completedSectionsIds, getHasEmployees, formProgressStatus]);
    const renderSections = () => {
        return menuSections.map((section) => (_jsx(SectionItem, { menuSection: section, currentSectionId: currentSectionId, completedSectionsIds: completedSectionsIds }, section.id)));
    };
    return (_jsx("div", Object.assign({ className: "menu", "data-testid": "menu" }, { children: _jsxs("div", Object.assign({ className: "menu__body" }, { children: [_jsx("div", Object.assign({ className: "menu__sections" }, { children: renderSections() })), _jsx("div", { className: "menu__sections__footer" })] })) })));
};
export default Menu;
