import { jsx as _jsx } from "react/jsx-runtime";
import Dropdown from '../../../../common/Selectors/Dropdown';
const DropdownQuestion = ({ content, handleOnChange, handleOnChangeMultiple, isOptionDisable }) => {
    const onChangeFn = (value) => {
        const response = {
            contentId: content.id,
            contentValue: value,
            valueType: content.type,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
        };
        handleOnChange(response);
    };
    const handleOnChangeMultipleFn = (values) => {
        let responses;
        if (values.length === 0) {
            responses = [
                {
                    contentId: content.id,
                    contentValue: null,
                    valueType: content.type,
                    ocs_entity: content.ocs_entity,
                    ocs_variable: content.ocs_variable,
                },
            ];
        }
        else {
            responses = values.map((newValue) => {
                return {
                    contentId: content.id,
                    contentValue: newValue,
                    valueType: content.type,
                    ocs_entity: content.ocs_entity,
                    ocs_variable: content.ocs_variable,
                };
            });
        }
        handleOnChangeMultiple(responses);
    };
    return (_jsx(Dropdown, { label: content.label, placeholder: content.placeholder, questionValues: content.questionValues, isMultiSelect: content.isMultiSelect, onChangeFn: onChangeFn, onChangeMultipleFn: handleOnChangeMultipleFn, isOptionDisable: isOptionDisable }));
};
export default DropdownQuestion;
