import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import FormResponseContext from '../../../../../../contexts/FormResponseContext';
import DpdContext from '../../../../../../contexts/DpdContext';
import BinaryQuestionButton from '../../../../../common/Buttons/BinaryQuestionButton';
import ContextualHelpModal from '../../../../../common/Modals/ContextualHelp/ContextualHelpModal';
import { getNewLinkToForQuestionS3S3C1 } from '../../../../../../utils/formSpecificsUtils';
import AlertDialog from '../../../../../common/Modals/AlertDialog';
import FormProgressContext from '../../../../../../contexts/FormProgressContext';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../../utils/common-utils';
const BinaryQuestion = ({ content, goToNextContent, alertContent }) => {
    const { t } = useTranslation();
    const { getFormResponses, saveFormResponse, getUserFormId, updateFormResponse } = useContext(FormResponseContext);
    const { saveCompletedSectionsIds, getCurrentSectionId, getCompletedSectionsIds } = useContext(FormProgressContext);
    const { getIsDpdMandatory } = useContext(DpdContext);
    const [showAlert, setShowAlert] = useState(false);
    const [useResponse, setUseResponse] = useState();
    const openAlert = () => {
        setShowAlert(true);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    const getResponse = (questionValue) => {
        return {
            contentId: content.id,
            contentValue: questionValue.value,
            valueType: content.type,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
        };
    };
    const onClick = (response, linkTo) => {
        if (getIsDpdMandatory() !== null && response.contentId === 's3.3-c1' && response.contentValue === '1') {
            linkTo = getNewLinkToForQuestionS3S3C1(linkTo, getFormResponses(), getIsDpdMandatory()); // TODO: refactor this specific case (see if possible to integrate to formModel.json?)
        }
        response.navNextLinkTo = linkTo;
        response.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo;
        const isDynamicUpdate = content.formResponse && content.isDynamicQuestion && content.formResponse.contentValue !== response.contentValue;
        if (isDynamicUpdate) {
            openAlert();
            setUseResponse(response);
        }
        else {
            saveFormResponse(response, getUserFormId(), t(content.title), getCurrentSectionId());
            goToNextContent(linkTo);
            scrollToTop();
        }
    };
    const updateResponse = () => {
        updateFormResponse(useResponse, getUserFormId(), t(content.title), getCurrentSectionId());
        const currentSectionId = getCurrentSectionId();
        const completedSectionsIds = getCompletedSectionsIds();
        if (currentSectionId) {
            const currentIndex = completedSectionsIds.indexOf(currentSectionId);
            const updatedSectionsIds = currentIndex !== -1 ? completedSectionsIds.slice(0, currentIndex + 1) : [...completedSectionsIds, currentSectionId];
            saveCompletedSectionsIds(updatedSectionsIds);
        }
        closeAlert();
        goToNextContent(useResponse.navNextLinkTo);
        scrollToTop();
    };
    const isResponded = (q) => {
        var _a;
        return ((_a = getFormResponses().find((fr) => fr.contentId === content.id)) === null || _a === void 0 ? void 0 : _a.contentValue) === q.value;
    };
    return (_jsxs("div", Object.assign({ className: "binary-container" }, { children: [content.infoModal && _jsx(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }), showAlert && _jsx(AlertDialog, { alertContent: alertContent, closeAlertDialog: closeAlert, handleAlertActionFn: updateResponse }), _jsx("div", Object.assign({ className: "question__answers-binary", "data-testid": "binary-type-question" }, { children: content.questionValues.map((q) => {
                    return (_jsx(BinaryQuestionButton, { onClickFn: () => onClick(getResponse(q), q.linkTo), questionValue: q, isResponded: isResponded(q) }, q.value));
                }) }))] })));
};
export default BinaryQuestion;
