import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import DisclaimerContainer from '../DisclaimerContainer';
const ResetPasswordConfirmation = ({ goToLogin }) => {
    const { t } = useTranslation();
    return (_jsxs(DisclaimerContainer, { children: [_jsx(Helmet, { children: _jsxs("title", { children: ["Signo | ", t('texts.resetPasswordPageTexts.passwordChangedText.pageTitle')] }) }), _jsx("h1", Object.assign({ className: "disclaimer-page__title", "data-testid": "reset-password-page-title" }, { children: t('texts.resetPasswordPageTexts.passwordChangedText.pageTitle') })), _jsx("div", { className: "disclaimer-page__description", dangerouslySetInnerHTML: { __html: t('texts.resetPasswordPageTexts.passwordChangedText.message') } }), _jsx("div", Object.assign({ className: "disclaimer-page__next" }, { children: _jsx(PrimaryButton, { text: t('texts.resetPasswordPageTexts.passwordChangedText.submitButton').toUpperCase(), onClickFn: goToLogin, testId: "login-btn" }) }))] }));
};
export default ResetPasswordConfirmation;
