import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { InputIcon } from '../../../types/FormModelTypes';
import { useTranslation } from 'react-i18next';
const Input = ({ id, placeholder, label, type, onChangeFn, error, icon, classname, fieldName, enabled, isEmpty, direction = '', maxLength, value = '', }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        if (isEmpty != undefined && isEmpty == true) {
            setInputValue('');
        }
    }, [isEmpty]);
    const handleOnChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onChangeFn(value, fieldName ? fieldName : null);
    };
    return (_jsxs("div", Object.assign({ className: `text-input__container ${direction}`, "data-testid": "text-type-question" }, { children: [_jsx("label", Object.assign({ htmlFor: "text-input", className: `form-label text-input__label ${classname} ${error && 'is-invalid'}`, "data-testid": "text-type-question-label" }, { children: t(label) })), _jsxs("div", Object.assign({ className: "input-group" }, { children: [icon && (_jsx("div", Object.assign({ className: "input-group-prepend" }, { children: _jsx("span", Object.assign({ className: "input-group-text text-input__icon" }, { children: _jsx("i", { "data-testid": "icon", className: ` ${icon === InputIcon.LINK ? 'bi bi-link' : icon === InputIcon.ENVELOPE ? 'bi bi-envelope-open' : ''}` }) })) }))), _jsx("input", { type: type, "data-testid": "text-type-question-input", id: id || 'text-input', className: `form-control text-input__element ${error && 'is-invalid'} ${icon && 'text-input--without-left-border'}`, placeholder: t(placeholder), role: type, "aria-describedby": "", onChange: handleOnChange, disabled: enabled != undefined ? !enabled : false, value: inputValue, maxLength: maxLength })] }))] })));
};
export default Input;
