import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import InfoContainer from './InfoContent/InfoContainer';
import MultiContentContainer from './MultiContent/MultiContentContainer';
import SingleContentContainer from './SingleContent/SingleContentContainer';
import IterativeResume from './IterativeContent/IterativeResume';
import IterativeOts from './IterativeContent/IterativeOts';
import { ContentType } from '../../../../types/FormModelTypes';
import MultiContentOTs from './MultiContent/MultiContentOTs';
import FormResponseContext from '../../../../contexts/FormResponseContext';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { scrollToTop } from '../../../../utils/common-utils';
const ContentContainer = ({ currentContent, goToNextContent, navToBackContent, navToNextContent }) => {
    const { t } = useTranslation();
    const [navigationIsVisible, setNavigationIsVisible] = useState(false);
    const [navBackButtonIsDisabled, setNavBackButtonIsDisabled] = useState(false);
    const [navNextButtonIsDisabled, setNavNextButtonIsDisabled] = useState(false);
    const { getFormResponses } = useContext(FormResponseContext);
    const alertContent = {
        actionButton: t('texts.modalsTexts.updateResponseAlert.actionButton'),
        title: t('texts.modalsTexts.updateResponseAlert.title'),
        body: t('texts.modalsTexts.updateResponseAlert.body'),
        closeButton: t('texts.modalsTexts.updateResponseAlert.closeButton'),
        subBody: t('texts.modalsTexts.updateResponseAlert.subBody'),
    };
    useEffect(() => {
        //TODO: Optimize this code
        const checkIfNavigationIsVisible = () => {
            if (currentContent.id === 'formStart-c1' ||
                currentContent.id.startsWith('s2-c4') ||
                currentContent.id.startsWith('s2-c5') ||
                (currentContent.id.startsWith('s6') && currentContent.id != 's6-c1') ||
                currentContent.id.startsWith('formEnd')) {
                setNavigationIsVisible(false);
            }
            else {
                setNavigationIsVisible(true);
            }
        };
        const checkIfBackButtonIsDisabled = () => {
            if (currentContent.id === 's1-c1') {
                setNavBackButtonIsDisabled(true);
            }
            else {
                setNavBackButtonIsDisabled(false);
            }
        };
        const checkIfNextButtonIsDisabled = () => {
            const formResponse = getFormResponses();
            setNavNextButtonIsDisabled(!formResponse.some((fr) => fr.contentId === currentContent.id) ||
                formResponse[formResponse.length - 1] == formResponse.find((fr) => fr.contentId === currentContent.id));
        };
        checkIfNavigationIsVisible();
        checkIfBackButtonIsDisabled();
        checkIfNextButtonIsDisabled();
    }, [currentContent, getFormResponses]);
    const renderComponent = () => {
        switch (currentContent === null || currentContent === void 0 ? void 0 : currentContent.type) {
            case ContentType.INFO:
                return _jsx(InfoContainer, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.ITERATIVE_RESUME:
                return _jsx(IterativeResume, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.ITERATIVE_OTS:
                return _jsx(IterativeOts, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.MULTI_CONTENT:
                return _jsx(MultiContentContainer, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.MULTI_CONTENT_OTS:
                return _jsx(MultiContentOTs, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.BINARY:
            case ContentType.MULTIPLE:
            case ContentType.TEXT:
                return _jsx(SingleContentContainer, { content: currentContent, goToNextContent: goToNextContent, alertContent: alertContent });
            default:
        }
    };
    return (_jsxs("div", Object.assign({ className: "main-content", "data-testid": "content" }, { children: [renderComponent(), navigationIsVisible && (_jsx("div", Object.assign({ className: "navigation-form" }, { children: !navBackButtonIsDisabled && (_jsx(Button, Object.assign({ size: "small", onClick: () => { navToBackContent(); scrollToTop(); }, startIcon: _jsx(ArrowBackRoundedIcon, { fontSize: "small" }) }, { children: _jsx("span", Object.assign({ className: "text-decoration-underline" }, { children: t('common.btn.back') })) }))) })))] })));
};
export default ContentContainer;
