import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './data/languages/en';
import es from './data/languages/es';
const resources = {
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
};
i18n
    .use(initReactI18next)
    .init({
    resources,
    lng: 'es',
    fallbackLng: 'es',
    supportedLngs: ['en', 'es'],
    react: {
        useSuspense: false,
    },
});
export default i18n;
