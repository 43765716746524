import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { LOGO_URL } from '../../utils/constants';
const MainContainer = ({ children, collectivePersonalization, isCollectiveApiResponded }) => {
    const { t } = useTranslation();
    const [registrationSuccessMessage, setRegistrationSuccessMessage] = useState(false);
    const { state } = useLocation();
    const { token, setToken } = useAuth();
    const [logoUrl, setLogoUrl] = useState(LOGO_URL);
    useEffect(() => {
        if (state === null || state === void 0 ? void 0 : state.successMessage) {
            setRegistrationSuccessMessage(true);
            history.replaceState(null, '');
        }
    }, [state]);
    useEffect(() => {
        if (isCollectiveApiResponded) {
            if (collectivePersonalization) {
                if (collectivePersonalization.logoUrl) {
                    setLogoUrl(collectivePersonalization.logoUrl);
                }
                else {
                    setLogoUrl(LOGO_URL);
                }
            }
            else {
                setLogoUrl(LOGO_URL);
            }
        }
    }, [collectivePersonalization, isCollectiveApiResponded]);
    const handleLogout = () => {
        setToken(null);
    };
    return (_jsxs("div", Object.assign({ className: "global-container" }, { children: [registrationSuccessMessage && (_jsx("div", Object.assign({ className: "registration-message toast-container position-absolute", id: "toastPlacement" }, { children: _jsxs("div", Object.assign({ className: "toast show", role: "alert", "aria-live": "assertive", "aria-atomic": "true" }, { children: [_jsxs("div", Object.assign({ className: "toast-header" }, { children: [_jsx("strong", Object.assign({ className: "me-auto" }, { children: "\u00A1Perfecto!" })), _jsx("button", { type: "button", className: "btn-close", "data-bs-dismiss": "toast", "aria-label": "Close" })] })), _jsx("div", Object.assign({ className: "toast-body" }, { children: state === null || state === void 0 ? void 0 : state.successMessage }))] })) }))), _jsx("div", Object.assign({ className: "left-sidebar" }, { children: _jsx("div", Object.assign({ className: "left-sidebar__logo" }, { children: _jsx("img", { alt: "Logo de SIGNO", src: logoUrl }) })) })), _jsx("div", Object.assign({ className: "mobile-header" }, { children: isCollectiveApiResponded && _jsx("img", { alt: "Logo de SIGNO", src: logoUrl }) })), children] })));
};
export default MainContainer;
