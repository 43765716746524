import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import { ContentType } from '../../../../../types/FormModelTypes';
import IterativeResumeItem from './IterativeResumeItem';
import BinaryQuestionButton from '../../../../common/Buttons/BinaryQuestionButton';
import { checkWetherToDisplayQuestionS5C8 } from '../../../../../utils/formSpecificsUtils';
import ContextualHelpModal from '../../../../common/Modals/ContextualHelp/ContextualHelpModal';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from '../../../../../utils/common-utils';
const IterativeResume = ({ content, goToNextContent }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { saveFormResponses, getFormResponseById, getUserFormId } = useContext(FormResponseContext);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    const responseExists = !!getFormResponseById(content.id);
    const responseHasIterativeContent = responseExists && ((_a = getFormResponseById(content.id).iterativeContent) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const getIterativeItemsArray = () => {
        if (responseHasIterativeContent) {
            const iterativeContent = getFormResponseById(content.id).iterativeContent;
            const responsesContainingIterativeItems = iterativeContent.flatMap((iterativeResponsesArray) => iterativeResponsesArray.content.find((response) => response.contentId === content.iterativeItems.itemId));
            const additionalIterativeValues = content.iterativeItems.additionalInfoId &&
                iterativeContent
                    .flatMap((iterativeResponsesArray) => iterativeResponsesArray.content.find((r) => r.contentId === content.iterativeItems.additionalInfoId))
                    .map((item) => item.contentValue);
            return responsesContainingIterativeItems.map((iterativeItemResponse, index) => (Object.assign({ contentId: iterativeItemResponse.contentId, value: iterativeItemResponse.contentValue }, (additionalIterativeValues ? { additionalInfo: additionalIterativeValues[index] } : {}))));
        }
    };
    const saveAndGoToNextContent = (questionValue) => {
        var _a;
        //TODO aqui se hace un find contra un literal "No" esto esta mal he incluido el "No" en los pricipales idiomas Europeos
        //const navNextLinkTo = !responseHasIterativeContent && content.questionValues.find((qv) => qv.text === 'No').linkTo;
        const noValuesLanguages = ['No', 'Non', 'Nein', 'Não', 'Nee', 'Nej', 'Ei', 'Nie', 'Нет', 'Όχι', 'Ez'];
        const navNextLinkTo = !responseHasIterativeContent && ((_a = content.questionValues.find((qv) => noValuesLanguages.includes(qv.text))) === null || _a === void 0 ? void 0 : _a.linkTo);
        const response = {
            contentId: content.id,
            contentValue: questionValue.value,
            valueType: ContentType.BINARY,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
            navBackLinkTo: content === null || content === void 0 ? void 0 : content.navBackLinkTo,
            navNextLinkTo,
            iterativeContent: [],
        };
        // Save response to binary question only the first time when arriving on the iterativeResume screen
        !responseHasIterativeContent && saveFormResponses([response], getUserFormId(), t(content.title), getCurrentSectionId());
        // TODO: refactor this specific case (see if possible to integrate to formModel.json?)
        if (responseHasIterativeContent && questionValue.linkTo === 's5-c3') {
            checkWetherToDisplayQuestionS5C8(getFormResponseById, content, questionValue);
        }
        goToNextContent(questionValue.linkTo);
        scrollToTop();
    };
    return (_jsxs("div", Object.assign({ className: "question iterative-resume-screen" }, { children: [_jsx("div", Object.assign({ className: "question__body" }, { children: _jsx("div", Object.assign({ className: "question__title", "data-testid": "question-title" }, { children: responseHasIterativeContent ? t(content.titleWhenIterativeItems) : t(content.title) })) })), _jsxs("div", Object.assign({ className: "binary-container" }, { children: [content.infoModal && _jsx(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }), _jsx("div", Object.assign({ className: "question__answers-binary", "data-testid": "binary-type-question" }, { children: content.questionValues.map((q) => {
                            return _jsx(BinaryQuestionButton, { onClickFn: () => { saveAndGoToNextContent(q); scrollToTop(); }, questionValue: q }, q.value);
                        }) }))] })), responseHasIterativeContent && (_jsx("div", Object.assign({ className: "iterative-items", "data-testid": "iterative-items" }, { children: (_b = getIterativeItemsArray()) === null || _b === void 0 ? void 0 : _b.map((iterativeItem, index) => {
                    return _jsx(IterativeResumeItem, { item: iterativeItem, index: index, iterativeContentParentId: content.id }, index);
                }) })))] })));
};
export default IterativeResume;
