import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import MultipleQuestionButton from '../../../../../common/Buttons/MultipleQuestionButton';
const MultiContentMultipleQuestion = ({ content, selectedValue, handleOnClick }) => {
    const { t } = useTranslation();
    const getResponse = (q) => {
        return {
            contentId: content.id,
            valueType: content.type,
            ocs_entity: q.ocs_entity,
            ocs_variable: q.ocs_variable,
        };
    };
    function isSelected(q) {
        return selectedValue && selectedValue.ocs_variables.includes(q.ocs_variable);
    }
    return (_jsxs("div", Object.assign({ className: "question__answers-multiple" }, { children: [_jsxs("label", Object.assign({ className: "question__answers-multiple-label" }, { children: [t(content.label), _jsx("br", {}), _jsx("span", Object.assign({ className: "sublabel" }, { children: t('texts.iterativeResumeTexts.multiSelectSubLabel') }))] })), _jsx("div", Object.assign({ className: "question__answers-multiple-buttons", "data-testid": "multiple-type-question" }, { children: content.questionValues.map((q) => {
                    return (_jsx(MultipleQuestionButton, { onClickFn: () => handleOnClick(getResponse(q)), text: t(q.text), selected: isSelected(q) }, q.value));
                }) }))] })));
};
export default MultiContentMultipleQuestion;
