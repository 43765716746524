import { createTheme } from '@mui/material';
// Accede a las variables de CSS
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-primary').trim();
const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary').trim();
// Si también necesitas las fuentes:
const mainFont = getComputedStyle(document.documentElement).getPropertyValue('--main-font').trim();
const secondaryFont = getComputedStyle(document.documentElement).getPropertyValue('--secondary-font').trim();
// Función que crea el tema dinámicamente a partir de las variables CSS
export const createDynamicTheme = () => {
    const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--color-primary')
        .trim();
    const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--color-secondary')
        .trim();
    const mainFont = getComputedStyle(document.documentElement)
        .getPropertyValue('--main-font')
        .trim();
    const secondaryFont = getComputedStyle(document.documentElement)
        .getPropertyValue('--secondary-font')
        .trim();
    return createTheme({
        cssVariables: true,
        palette: {
            primary: {
                main: primaryColor || '#1976d2', // Fallback
            },
            secondary: {
                main: secondaryColor || '#dc004e', // Fallback
            },
        },
        customImages: {
            logo: '/assets/images/Logo_Signo.png',
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                xxl: 1400,
            },
        },
        typography: {
            fontFamily: mainFont || 'Roboto, sans-serif',
            h1: {
                fontSize: '3rem',
                fontWeight: 500,
            },
            h2: {
                fontSize: '16px',
                fontWeight: 500,
                color: primaryColor,
            },
            body1: {
                fontFamily: secondaryFont || 'Arial, sans-serif',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: mainFont || 'Roboto, sans-serif',
                        fontSize: '16px',
                        fontWeight: '600',
                    },
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: theme.palette.secondary.main,
                        boxShadow: 'none',
                        border: 'none',
                        '&:before': { display: 'none' },
                    }),
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: () => ({
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        padding: 0,
                        '& .MuiAccordionSummary-content': {
                            marginLeft: '16px !important',
                        },
                    }),
                },
            },
        },
    });
};
/* const themeDefault = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--color-primary'),
    },
    secondary: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--color-secondary'),
    },
  },
  customImages: {
    //TODO: Esto se usa? Habria que cambiarlo por: images.LOGO_URL
    logo: '/assets/images/Logo_Signo.png', // Ruta de tu logotipo
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: mainFont,
          fontSize: '16px',
          fontWeight: '600',
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
          boxShadow: 'none',
          border: 'none',
          '&:before': { display: 'none' },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: () => ({
          display: 'flex',
          flexDirection: 'row-reverse',
          padding: 0,
          '& .MuiAccordionSummary-content': {
            marginLeft: '16px !important', // Añade margen al texto para separarlo de la flecha
          },
        }),
      },
    },
  },
}); */
// Exporta un tema inicial (se usa para inicializar)
const themeDefault = createDynamicTheme();
export default themeDefault;
